import { api } from 'config/variables';
import compact from 'lodash/compact';
// import { oauth_client_id } from 'apilist/apiInstance';
import moment from 'moment';
import cloneDeepWith from 'lodash/cloneDeepWith';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import toString from 'lodash/toString';
import { dp_costing_keys } from 'variables/general';

var basename = '/';

export const createCookie = (
	name,
	value,
	expTimeReq,
	expiryTime,
	path,
	source = false,
	decode = false
) => {
	if (source) {
		setCrossSubdomainCookie(name, value, 3, decode);
	} else {
		document.cookie = `${name}=${value}${
			expTimeReq ? `; max-age=${expiryTime}` : ''
		}; path=${path}`;
	}
	// secure, httponly
};
function setCrossSubdomainCookie(name, value, days, decode) {
	try {
		const valueWill = decode ? value : encodeURI(value);
		const assign = name + '=' + valueWill + ';';
		const d = new Date();
		d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
		const expires = 'expires=' + d.toUTCString() + ';';
		const path = 'path=/;';
		const domain = 'domain=' + getDomin() + ';';
		document.cookie = assign + expires + path + domain;
	} catch (err) {
		console.log(err);
	}
}

const getDomin = () => {
	let domin = document?.domain;
	if (domin != 'localhost' && domin != '127.0.0.1') {
		domin = document?.domain?.match(/[^\.]*\.[^.]*$/)[0]; //eslint-disable-line
	}
	return domin;
};

export const readCookie = (name, showParsed) => {
	let cookieVal = document.cookie
		.split(';')
		.find((item) => item.trim().startsWith(name));
	cookieVal = cookieVal && cookieVal.replace(`${name}=`, '');
	if (showParsed && cookieVal) {
		return JSON.parse(cookieVal);
	} else {
		return cookieVal && cookieVal.trim();
	}
};

export const eraseCookie = (name) => {
	createCookie(name, '', true, -1, '/');
};

export const getValidCookie = (name) => {
	let cookie = document.cookie;
	let cookArr = cookie.split(';');
	let bearerTokens = cookArr.map((c) => {
		if (c.includes('bearer')) {
			return c.trim().replace(`${name}=`, '');
		}
		return '';
	});
	bearerTokens = compact(bearerTokens);
	return bearerTokens[0];
};

export const reAuthenticateUser = () => {
	let currentTime = moment().utc();
	let expiryTime = readCookie('expires');
	expiryTime = moment(expiryTime);
	let minutesToExpiry = expiryTime.diff(currentTime, 'minutes');
	return minutesToExpiry <= 10;
};

export const getParameterByNameAndType = (name, type, url) => {
	var match;
	switch (type) {
		case 'hash':
			match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location[type]);
			break;
		case 'search':
			match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location[type]);
			break;
		default:
			match = RegExp('[?&]' + name + '=([^&]*)').exec(url[type]);
			break;
	}
	return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

const generateRandomString = () => {
	let randomString =
		Math.random().toString(36).substring(2, 15) +
		Math.random().toString(36).substring(2, 15);
	return randomString;
};

export const goToLoginPage = (goBackURL = '/client/dashboard') => {
	let randomStr = generateRandomString();
	console.log(goBackURL);
	const queryParams = new URLSearchParams(window.location.search);
	let url = `${api.sso_url}/login?source=rb`;
	if (queryParams) {
		url = url + '&' + queryParams;
	}
	// let subdomain = window.location.host.split('.')[0];
	// let server = window.location.host.split('.')[1];
	// console.log(subdomain, server, api.server, api);
	// let redirect_uri = api.isDev
	// 	? `${api.localServer}/auth/confirm`
	// 	: `https://${subdomain}.${server}.in/auth/confirm`;
	// let params = `?client_id=${oauth_client_id}&response_type=token&scope=read write&state=${randomStr}&redirect_uri=${redirect_uri}?next=${goBackURL}`;
	// let url = `${api.sso_url}/login/accounts/o/authorize/${params}`;
	// let url = `${api.sso_url}/login?source=rb`;
	// console.log(params);
	// let url = api.isDev
	// 	? `${api.baseURL}accounts/o/authorize/${params}`
	// 	: `https://sso.${api.server}.in/accounts/o/authorize/${params}`;
	return { randomStr, url };
};

export const logoutUser = () => {
	let url =
		getSource() == 'pr'
			? `${api.sso_pickr_url}`
			: `${api.sso_url}/logout?source=rb`;
	return url;
};

export const getSource = () => {
	let source = readCookie('source');
	source = source == 'null' ? 'sr' : source;
	return source;
};

export const support_mail_according_to_source = () => {
	let default_support_email = 'cargosupport@shiprocket.com';
	if (getSource() == 'pr') {
		default_support_email = 'cargosupport@shiprocket.com';
	}
	return default_support_email;
};

export const convertObjectValuesToString = (obj) => {
	return cloneDeepWith(obj, (value) => {
		return !isPlainObject(value) ? toString(value) : undefined;
	});
};

export const reloadUser = () => {
	eraseCookie('bearer');
	eraseCookie('expires');
	eraseCookie('client');
};

export const formatCostingData = (field, value) => {
	if (dp_costing_keys.currency.includes(field)) {
		let val =
			value ?? ''
				? value.toString()
					? parseFloat(value).toLocaleString('en-IN', {
							minimumFractionDigits: 0,
							maximumFractionDigits: 2,
							style: 'currency',
							currency: 'INR',
					  })
					: '-'
				: '-';
		return val.toString().replace('-err', '');
	} else if (dp_costing_keys.weight.includes(field)) {
		return value ? `${value.toString().replace('-err', '')} kg.` : '-';
	} else if (dp_costing_keys.date.includes(field)) {
		return moment(value.toString().replace('-err', '')).format('DD-MM-YYYY');
	} else {
		return value ? value.toString().replace('-err', '') : value;
	}
};

export const calculateVolumetricWt = (
	{ length, width, height, units, unit },
	cft
) => {
	cft = cft || 6;
	if (unit == 'inch') {
		length = convertInchToCm(length);
		width = convertInchToCm(width);
		height = convertInchToCm(height);
	}
	if (length && width && height) {
		return ((length * width * height * cft) / 27000) * units;
	}
};

const convertInchToCm = (length_in_inch) => {
	return Math.round(2.54 * length_in_inch * 100 + Number.EPSILON) / 100;
};

export const toCurrency = (
	value,
	indianNumberFormat = false,
	removeFraction
) => {
	if (!isNaN(value)) {
		return parseFloat(value).toLocaleString('en-IN', {
			minimumFractionDigits: removeFraction ? 0 : 2,
			maximumFractionDigits: removeFraction ? 0 : 2,
			style: !indianNumberFormat ? 'currency' : undefined,
			currency: !indianNumberFormat ? 'INR' : undefined,
		});
	} else {
		return 0;
	}
};

export const checkIfAnyKeyInObjectHasValue = (obj) => {
	// obj = { a: '', b: '', c: undefined} returns false
	// obj = { a: '', b: 'a', c: undefined} returns true
	return Object.values(obj).some((el) => !!el);
};

export const handleScrollToTop = () => {
	document.querySelector('#app-header').scrollIntoView({
		behavior: 'smooth',
	});
};

export const locationTypeHandler = (working) => {
	const odas = ['ODA', 'ODA-A', 'ODA-B', 'ODA-C', 'ODA-D', 'ODA-1', 'ODA-2'];
	if (!isEmpty(working)) {
		if (working?.recipient_location_type || working?.sender_location_type) {
			if (
				odas.includes(working.recipient_location_type) ||
				odas.includes(working.sender_location_type)
			) {
				return 'ODA';
			} else if (
				[
					working.recipient_location_type,
					working.sender_location_type,
				].includes('OPA')
			) {
				return 'OPA';
			} else if (working.sender_location_type.includes('safextension')) {
				return working.sender_location_type;
			} else if (working.recipient_location_type.includes('safextension')) {
				return working.recipient_location_type;
			} else {
				return 'Regular';
			}
		} else {
			return '-';
		}
	}
};

export const fromLocationTypeHandler = (working) => {
	const odas = [
		'OPA',
		'OPA2',
		'OPA-1',
		'OPA-2',
		'ODA',
		'ODA-A',
		'ODA-B',
		'ODA-C',
		'ODA-D',
		'ODA-1',
		'ODA-2',
	];
	if (!isEmpty(working)) {
		if (working?.sender_location_type) {
			if (odas.includes(working.sender_location_type)) {
				return 'OPA';
			} else if (working.sender_location_type.includes('safextension')) {
				return working.sender_location_type;
			} else {
				return 'Regular';
			}
		} else {
			return '-';
		}
	}
};

export const toLocationTypeHandler = (working) => {
	const odas = ['ODA', 'ODA-A', 'ODA-B', 'ODA-C', 'ODA-D', 'ODA-1', 'ODA-2'];
	if (!isEmpty(working)) {
		if (working?.recipient_location_type) {
			if (odas.includes(working.recipient_location_type)) {
				return 'ODA';
			} else if (working.recipient_location_type.includes('safextension')) {
				return working.recipient_location_type;
			} else {
				return 'Regular';
			}
		} else {
			return '-';
		}
	}
};

export const scrollToElement = (ele) => {
	document.querySelector(ele).scrollIntoView();
};

export const getClientID = (client) => {
	return client?.role === 'customer'
		? get(client, 'customer.client.id', undefined)
		: get(client, 'client_poc.client.id', undefined);
};

export const isCustomerUser = () => {
	const client = readCookie('client', true);
	return get(client, 'is_customer_user');
};

export const ScrolltoTop = () => {
	window.sideContent?.current?.scrollTo({
		top: 0,
		behavior: 'smooth',
	});
};

export const setBasename = () => {
	basename = '/';
};

export const getBasename = () => {
	let client = readCookie('client', true) || '';
	if (client?.customer?.client?.client_name) {
		let client_slug = client.customer.client.client_name;
		client_slug = client_slug.replaceAll(' ', '-').toLowerCase();
		basename = '/' + client_slug;
	}
	if (client?.client_poc?.client?.client_name) {
		let client_slug = client.client_poc.client.client_name;
		client_slug = client_slug.replaceAll(' ', '-').toLowerCase();
		basename = '/' + client_slug;
	}
	return basename;
};

export const getToday = () => {
	return moment();
};

export const getKycStatus = () => {
	const client = readCookie('client', true);
	let kycStatus = get(client, 'client_poc.client.status', undefined);
	if (kycStatus === 'pending') return false;
	else return true;
};

export const tConv24 = (time24) => {
	var H;
	var h;
	var ampm;
	var ts = time24;
	if (ts) {
		H = +ts.substr(0, 2);
		h = H % 12 || 12;
		h = h < 10 ? '0' + h : h; // leading 0 at the left for 1 digit hours
		ampm = H < 12 ? ' AM' : ' PM';
		ts = h + ts.substr(2, 3) + ampm;
	}

	return ts;
};

export const Stringify = (data) => {
	let stringify_value = JSON.stringify(data);
	return stringify_value;
};

export const ParseStringifyData = (data) => {
	let parsed_value = data;
	if (typeof data == 'string' && !data.includes('-') && data) {
		parsed_value = JSON.parse(data);
	}
	return parsed_value;
};

export const check_client_on_smart_plan = (client_accessed_by_admin = null) => {
	let clientsmartplan = null;
	let is_staff = readCookie('client', true).is_staff;
	if (is_staff) {
		clientsmartplan = client_accessed_by_admin?.rb_plan_name;
	} else {
		let client = readCookie('client', true);
		clientsmartplan = client?.client_poc?.client?.rb_plan_name;
	}
	return clientsmartplan == 1;
};

export const notification_url = () => {
	let client = readCookie('client', true);
	const url = `${api.socket_url}${getSource() + '_' + client?.id}/`;
	return url;
};
export const parent_wallet_type_pr_check = () => {
	let temp = false;
	let client = readCookie('client', true)?.client_poc?.client;
	if (getSource() == 'pr' && client?.parent_wallet_type) {
		temp = client.parent_wallet_type == 'wallet';
	} else if (
		(getSource() == 'pr' && client?.parent_wallet_type == null) ||
		(getSource() == 'pr' && client?.parent_wallet_type == 'null')
	) {
		temp = false;
	} else {
		temp = true;
	}
	return temp;
};

export const check_courier_reassignment_permission = () => {
	let client = readCookie('client', true);
	// if (client.is_staff) {
	// 	return false;
	// }
	client = client?.client_poc?.client;
	return !client?.is_courier_reassigment_allowed;
};

export const checkVASPermissions = (key) => {
	if (key) {
		let client_ = readCookie('client', true);
		if (client_.is_staff) {
			return true;
		}
		client_ = client_?.client_poc?.client;
		return !!client_[key];
	}
	return true;
};

export const handleTabClosing = () => {
	let as_user_cookie = readCookie('as_user_id');
	if (as_user_cookie) {
		eraseCookie('client');
		eraseCookie('bearer');
		eraseCookie('expires');
		setCrossSubdomainCookie('as_user_id', '', -1);
	}
};

export const srPlanType = () => {
	let client = readCookie('client', true);
	client = client?.client_poc?.client;
	if (client?.sr_id) {
		if (client?.sr_plan_name) {
			return '(' + client?.sr_plan_name + ')';
		} else {
			return '(Basic)';
		}
	}
};

export const rbPlanType = () => {
	let client = readCookie('client', true);
	client = client?.client_poc?.client;
	if (client?.rb_plan_name) {
		if (client?.rb_plan_name == '1') {
			return '(Advantage)';
		}
	} else {
		return '(Basic)';
	}
};

export const stringNumber = (val, convert_to_rupee = true) => {
	let num = parseFloat(val);
	if (!isNaN(num)) {
		if (num <= 0) {
			return num;
		}
		return convert_to_rupee ? toCurrency(num) : num;
	}
	return val;
};
