export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST';
export const ADD_WAREHOUSE = 'ADD_WAREHOUSE';
export const SET_CUSTOMER_EDIT_STATUS = 'SET_CUSTOMER_EDIT_STATUS';

export const setCustomerList = (payload) => {
	return {
		type: SET_CUSTOMER_LIST,
		payload,
	};
};

export const setCustomerEditStatus = (payload) => {
	return {
		type: SET_CUSTOMER_EDIT_STATUS,
		payload,
	};
};
