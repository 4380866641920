import { axiosInstance } from './apiInstance';
import { setUser } from 'actions/user';
import get from 'lodash/get';
import { reloadUser } from 'utils/functions';
import * as urls from './urls';
import { store } from '../index';

export const pincodeServiceability = (
	from_pincode,
	to_pincode,
	for_service_list = false,
	client_id = null
) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getPickupServiceability, {
				params: {
					from_pincode: from_pincode || undefined,
					to_pincode: to_pincode || undefined,
					for_service_list: for_service_list || false,
					client_id: client_id || undefined,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getPincodeDownload = (queryParams) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getZoneDownload, {
				params: {
					...queryParams,
				},
			})
			.catch((err) => {
				if (err === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const downloadServiceability = (pincode) => {
	let emails = store.getState().serviceability.add_on_emails.toString();
	return (dispatch) => {
		return axiosInstance
			.get(urls.downloadPincodeServiceability, {
				params: {
					emails,
					pincode: pincode || undefined,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
