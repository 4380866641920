import { connect } from 'react-redux';
import {
	optSecureShipment,
	getAutoAssignCarrier,
	updateAutoAssignCarrier,
	createAutoAssignCarrier,
	deleteAutoAssignCarrier,
	getAllModeLists,
	generateSignedUrl,
	profile,
	uploadFiletoS3,
	getClientDetails,
	saveRule,
	getDeliveryPartners,
	updateRule,
	editRule,
} from 'apilist';
/*  This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component - in this case, the counter:   */
import { setBackdrop } from 'actions/appEngine';
import { setUser } from 'actions/user';

import AddRuleForm from '../components/AddRuleForm';
import { setCarrierSettingEditData } from 'actions/carrierSettings';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. Here we are
    implementing our wrapper around increment; the component doesn't care   */

const mapDispatchToProps = {
	optSecureShipment,
	setBackdrop,
	setUser,
	getAutoAssignCarrier,
	updateAutoAssignCarrier,
	createAutoAssignCarrier,
	deleteAutoAssignCarrier,
	getAllModeLists,
	generateSignedUrl,
	profile,
	uploadFiletoS3,
	getClientDetails,
	saveRule,
	getDeliveryPartners,
	updateRule,
	setCarrierSettingEditData,
	editRule,
};

const mapStateToProps = (state) => ({
	user: state.user,
	appEngine: state.appEngine,
	pickup: state.pickup,
	carrierSetting: state.carrierSetting,
});

const containerCreator = connect(mapStateToProps, mapDispatchToProps);

export const RulesContainer = containerCreator(AddRuleForm);
