import { axiosInstance } from './apiInstance';
import { setUser } from 'actions/user';
import { reloadUser } from 'utils/functions';
import * as urls from './urls';
import { get } from 'lodash';

export const getAutoAssign = (client_id) => {
	let params = {};
	if (client_id) {
		params.client_id = client_id;
	}
	return (dispatch) => {
		return axiosInstance
			.get(urls.autoAssignCarrier, { params })
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const createAutoAssign = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.autoAssignCarrier, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if ((response.status === 201) | (response.status === 200)) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const editAutoAssign = (obj, autoAssignId) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.put(urls.autoAssignCarrier + `${autoAssignId}/`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const deleteAutoAssign = (id) => {
	return (dispatch) => {
		const data = { is_active: false };
		return axiosInstance
			.put(urls.autoAssignCarrier + `${id}/`, data)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'delete',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200 || response.status === 204) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getAllModeLists = () => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getModeList + '?get_mode_wise_dp=true')
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const saveRule = (data) => {
	return (dispatch) => {
		return axiosInstance
			.post(urls.saveRule, data)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
export const updateRule = (data) => {
	return (dispatch) => {
		return axiosInstance
			.post(urls.updateRule, data)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const editRule = (data) => {
	return (dispatch) => {
		return axiosInstance
			.put(urls.saveRule + `${data.id}/`, data)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
