export const SET_DP_ACCOUNTS_LIST = 'SET_DP_ACCOUNTS_LIST';
export const ADD_DP_ACCOUNT = 'ADD_DP_ACCOUNT';
export const SET_DP_ACCOUNT_EDIT_STATUS = 'SET_DP_ACCOUNT_EDIT_STATUS';

export const setDPAccountList = (payload) => {
	return {
		type: SET_DP_ACCOUNTS_LIST,
		payload,
	};
};

export const setDPAccountEditStatus = (payload) => {
	return {
		type: SET_DP_ACCOUNT_EDIT_STATUS,
		payload,
	};
};
