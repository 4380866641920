export const RULE_EDIT_DATA = 'RULE_EDIT_DATA';
export const CARRIER_SETTING_ID = 'CARRIER_SETTING_ID';
export const SET_RULE_PRIORITY = 'SET_RULE_PRIORITY';

export const setCarrierSettingEditData = (payload) => {
	return {
		type: RULE_EDIT_DATA,
		payload,
	};
};

export const setCarrierSettingId = (payload) => {
	return {
		type: CARRIER_SETTING_ID,
		payload,
	};
};
export const setRulePriority = (payload) => {
	return {
		type: SET_RULE_PRIORITY,
		payload,
	};
};
