import { axiosInstance } from './apiInstance';
import { setUser } from 'actions/user';
import get from 'lodash/get';
import { reloadUser } from 'utils/functions';
import * as urls from './urls';

export const getFinalInvoicingData = (
	pageNo,
	filterVals,
	page_size,
	isReportDownload,
	isPNLReportDownload
) => {
	let {
		dateRange,
		deliveryPartner,
		selectedClient,
		shipmentMode,
		awbNo,
		status,
		fromZone,
		toZone,
	} = filterVals;
	let url = urls.getDPCostingList;
	if (isReportDownload) {
		url = urls.downloadDPCostingReport;
	} else if (isPNLReportDownload) {
		url = urls.downloadPnLReport;
	}
	return (dispatch) => {
		if (deliveryPartner) {
			return axiosInstance
				.get(url, {
					params: {
						page: pageNo,
						page_size,
						client__id: selectedClient || undefined,
						delivery_partner__id: deliveryPartner || undefined,
						mode__id: shipmentMode || undefined,
						ship_date_after:
							(dateRange && dateRange.length && dateRange[0]) || undefined,
						ship_date_before:
							(dateRange && dateRange.length && dateRange[1]) || undefined,
						waybill_no: awbNo || undefined,
						status: status || undefined,
						sender_zone: fromZone || undefined,
						recipient_zone: toZone || undefined,
					},
				})
				.catch((err) => {
					let errResponse = get(err, 'response.status', false);
					if (errResponse === 401) {
						reloadUser();
						dispatch(
							setUser({
								reAuth: false,
								showReAuthModal: false,
								requestType: 'get',
							})
						);
						window.location.reload();
					}
					throw err;
				})
				.then((response) => {
					if (response.status === 200) {
						return response.data;
					} else {
						let err = new Error(response.data.msg);
						throw err;
					}
				});
		} else {
			return new Promise((resolve) => {
				resolve({
					results: [],
				});
			});
		}
	};
};

export const editCalculatedWorking = (obj, dp_costing_id) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.put(`/dp_costing/${dp_costing_id}`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const approveBulkInvoice = (data) => {
	let body = JSON.stringify(data);
	return (dispatch) => {
		return axiosInstance
			.put(urls.approveBulkCosting, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const generateInvoices = (reqObj, type) => {
	let body = JSON.stringify(reqObj);
	let url = type === 'final' ? 'final_invoice' : 'interim_invoice';
	return (dispatch) => {
		return axiosInstance
			.post(`/client/${url}/`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getZoneList = (dp, mode) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getZoneList, {
				params: {
					delivery_partner__id: dp || undefined,
					mode__id: mode || undefined,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getClientWithShipmentsToBeInvoiced = (obj = {}) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getClientsToBeInvoiced, {
				params: {
					all_records: true,
					...obj,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
