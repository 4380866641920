import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import TextField from '@material-ui/core/TextField';
import UploadFile from 'components/Upload/Upload';
// import CustomInput from 'components/CustomInput/CustomInput.js';

// import FormControl from '@material-ui/core/FormControl';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 0.5,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		// height: 300,
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
		// padding: '20px',
	},
	modecard: {
		border: '1px solid grey',
		// minHeight: '50px',
		padding: '10px',
		// paddingBottom: '25px',
		boxShadow: '4px 6px 10px 0 rgba(209,209,209,1)',
		cursor: 'pointer',
		// textAlign: 'center',
	},
	selected: {
		border: '1px solid blue',
	},
	paddingContainer: {
		padding: '0px!important',
		paddingBottom: '10px!important',
	},
	paddingContainerTop: {
		paddingTop: '2px!important',
	},
}));

export default function RulesTabs(props) {
	const {
		modes,
		orderTypes,
		is_appointment_taken,
		sourcePincodes,
		destinationPincodes,
		setSetting,
		handleFileUpload,
		setSourcePincodeFileName,
		setDestPincodeFileName,
		destination_pincode_file_name,
		source_pincode_file_name,
		weightRange,
		invoiceValue,
		fromZone,
		toZone,
	} = props;
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		// <div className={classes.root}>
		<GridContainer>
			<GridItem xs={4} sm={4} md={4}>
				<Tabs
					orientation="vertical"
					variant="scrollable"
					value={value}
					centered={false}
					onChange={handleChange}
					aria-label="Vertical tabs example"
					className={classes.tabs}
					// style={{ alignContent: 'inherit' }}
				>
					<Tab label="Service Type" {...a11yProps(0)} />
					{/* <Tab label="Product" {...a11yProps(2)} /> */}
					<Tab label="Order Type" {...a11yProps(1)} />
					<Tab label="Appointment" {...a11yProps(2)} />
					<Tab label="Pickup Pincode" {...a11yProps(3)} />

					<Tab label="Destination Pincode" {...a11yProps(4)} />
					<Tab label="From Zone" {...a11yProps(5)} />
					<Tab label="To Zone" {...a11yProps(6)} />
					<Tab label="Weight Range" {...a11yProps(7)} />
					<Tab label="Invoice Value" {...a11yProps(8)} />
				</Tabs>
			</GridItem>
			<GridItem
				xs={8}
				sm={8}
				md={8}
				className={classes.paddingContainer}
				// style={{ padding: '0px 0px !important' }}
			>
				<TabPanel value={value} index={0}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<label>
								<b>Set Courier Priority</b>
							</label>
						</GridItem>
						<GridItem
							xs={4}
							sm={4}
							md={4}
							onClick={() => {
								setSetting('mode', 'air');
							}}
						>
							<Card
								className={`${modes.includes('air') && classes.selected} ${
									classes.modecard
								}`}
							>
								<h5>Air</h5>
							</Card>
						</GridItem>
						<GridItem
							xs={4}
							sm={4}
							md={4}
							onClick={() => {
								setSetting('mode', 'surface');
							}}
						>
							<Card
								className={`${modes.includes('surface') && classes.selected} ${
									classes.modecard
								}`}
							>
								<h5>Surface</h5>
							</Card>
						</GridItem>
					</GridContainer>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<label>
								<b>Order Type</b>
							</label>
						</GridItem>
						<GridItem
							xs={4}
							sm={4}
							md={4}
							onClick={() => {
								setSetting('order_type', 'cod');
							}}
						>
							<Card
								className={`${orderTypes.includes('cod') && classes.selected} ${
									classes.modecard
								}`}
							>
								<h5>COD</h5>
							</Card>
						</GridItem>
						<GridItem
							xs={4}
							sm={4}
							md={4}
							onClick={() => {
								setSetting('order_type', 'prepaid');
							}}
						>
							<Card
								className={`${
									orderTypes.includes('prepaid') && classes.selected
								} ${classes.modecard}`}
							>
								<h5>Prepaid</h5>
							</Card>
						</GridItem>
						{/* <GridItem
							xs={4}
							sm={4}
							md={4}
							onClick={() => {
								setSetting('order_type', 'reverse');
							}}
						>
							<Card
								className={`${
									orderTypes.includes('reverse') && classes.selected
								} ${classes.modecard}`}
							>
								<h5>Reverse</h5>
							</Card>
						</GridItem> */}
					</GridContainer>
				</TabPanel>
				<TabPanel value={value} index={2} className={classes.paddingContainer}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<label>
								<b>Appointment Delivery Shipment</b>
							</label>
						</GridItem>
						<GridItem
							xs={4}
							sm={4}
							md={4}
							onClick={() => {
								setSetting('is_appointment_taken', 'yes');
							}}
						>
							<Card
								// className={`${classes.selected} ${classes.modecard}`}
								className={`${
									is_appointment_taken == true && classes.selected
								} ${classes.modecard}`}
							>
								<h5>YES</h5>
							</Card>
						</GridItem>
					</GridContainer>
				</TabPanel>
				<TabPanel value={value} index={3} className={classes.paddingContainer}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={12}
							md={12}
							className={classes.paddingContainer}
						>
							<label>
								<b>Select Pincode</b>
							</label>
						</GridItem>
						<GridItem xs={12} sm={12} md={12}>
							<TextField
								id="outlined-multiline-static"
								multiline
								rows={4}
								fullWidth
								helperText={
									'You can enter multipal pincodes with comma seperated for eg:123456,789012'
								}
								// defaultValue="Default Value"
								variant="outlined"
								value={sourcePincodes}
								onChange={(e) => {
									setSetting('source_pincode', e.target.value);
								}}
							/>
						</GridItem>
						<GridItem
							xs={12}
							sm={12}
							md={12}
							className={classes.paddingContainer}
							style={{ marginTop: '13px' }}
						>
							<label>
								<b>Or Upload pincode List</b>
							</label>
						</GridItem>
						<GridItem xs={6} sm={6} md={6} style={{ marginTop: '13px' }}>
							<UploadFile
								showIcon={false}
								acceptedFiles=".xlsx, .jpg, .jpeg"
								fileType={'Source Pincodes'}
								url={`uploads/files/`}
								uploadFunction={(path) => {
									handleFileUpload('source_pincode', path);
								}}
								setFileName={setSourcePincodeFileName}
								fileName={source_pincode_file_name}
								// uploadState={}
								{...props}
							/>
						</GridItem>
						<GridItem xs={8} sm={8} md={8} style={{ marginTop: '13px' }}>
							<InputLabel
								htmlFor="order-pincode"
								onClick={() => {
									window.open(
										'https://garud-assets.s3.ap-south-1.amazonaws.com/common-files/PickupPincode_sample_file.xlsx'
									);
								}}
								style={{ cursor: 'pointer' }}
							>
								(Download Sample CSV File)
							</InputLabel>
						</GridItem>
					</GridContainer>
				</TabPanel>

				<TabPanel value={value} index={4}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={12}
							md={12}
							className={classes.paddingContainer}
						>
							<label>
								<b>Select Destination Pincode</b>
							</label>
						</GridItem>
						<GridItem xs={12} sm={12} md={12}>
							<TextField
								id="outlined-multiline-static"
								multiline
								rows={4}
								fullWidth
								helperText={
									'You can enter multiple pincodes with comma seperated for eg:123456,789012'
								}
								// defaultValue="Default Value"
								value={destinationPincodes}
								variant="outlined"
								onChange={(e) => {
									setSetting('destination_pincode', e.target.value);
								}}
							/>
						</GridItem>
						<GridItem
							xs={12}
							sm={12}
							md={12}
							className={classes.paddingContainer}
							style={{ marginTop: '13px' }}
						>
							<label>
								<b>Or Upload pincode List</b>
							</label>
						</GridItem>
						<GridItem xs={6} sm={6} md={6} style={{ marginTop: '13px' }}>
							<UploadFile
								showIcon={false}
								acceptedFiles=".xlsx, .jpg, .jpeg"
								fileType={'Destination Pincodes'}
								url={`uploads/files/`}
								uploadFunction={(path) => {
									handleFileUpload('destination_pincode', path);
								}}
								setFileName={setDestPincodeFileName}
								fileName={destination_pincode_file_name}
								// uploadState={}
								{...props}
							/>
						</GridItem>
						<GridItem xs={9} sm={9} md={9} style={{ marginTop: '13px' }}>
							<InputLabel
								htmlFor="order-pincode"
								onClick={() => {
									window.open(
										'https://garud-assets.s3.ap-south-1.amazonaws.com/common-files/DestinationPincode_sample_file.xlsx'
									);
								}}
								style={{ cursor: 'pointer' }}
							>
								(Download Sample CSV File)
							</InputLabel>
						</GridItem>
					</GridContainer>
				</TabPanel>
				<TabPanel value={value} index={5}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={12}
							md={12}
							className={classes.paddingContainer}
						>
							<label>
								<b>From Zone</b>
							</label>
						</GridItem>
						<GridItem xs={12} sm={12} md={12}>
							<TextField
								id="outlined-multiline-static"
								multiline
								rows={4}
								fullWidth
								helperText={
									'You can enter multipal from zone with comma seperated for eg:South,North'
								}
								// defaultValue="Default Value"
								variant="outlined"
								value={fromZone}
								onChange={(e) => {
									setSetting('from_zone', e.target.value);
								}}
							/>
						</GridItem>
					</GridContainer>
				</TabPanel>
				<TabPanel value={value} index={6}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={12}
							md={12}
							className={classes.paddingContainer}
						>
							<label>
								<b>To Zone</b>
							</label>
						</GridItem>
						<GridItem xs={12} sm={12} md={12}>
							<TextField
								id="outlined-multiline-static"
								multiline
								rows={4}
								fullWidth
								helperText={
									'You can enter multipal to zone with comma seperated for eg:South,North'
								}
								// defaultValue="Default Value"
								variant="outlined"
								value={toZone}
								onChange={(e) => {
									setSetting('to_zone', e.target.value);
								}}
							/>
						</GridItem>
					</GridContainer>
				</TabPanel>
				<TabPanel value={value} index={7}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={12}
							md={12}
							className={classes.paddingContainer}
						>
							<label>
								<b>Select Weight Range</b>
							</label>
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<TextField
								id="outlined-multiline-static"
								label="MIN"
								placeholder="MIN"
								fullWidth
								helperText={
									weightRange.min_state === 'error' &&
									'Min value should be less than Max value'
								}
								error={weightRange.min_state === 'error'}
								type="number"
								variant="outlined"
								value={weightRange.min}
								onChange={(e) => {
									setSetting(
										'weight',
										e.target.value !== '' ? parseInt(e.target.value) : '',
										'min'
									);
									if (weightRange.max < parseInt(e.target.value)) {
										setSetting('weight', 'error', 'min_state');
									} else {
										setSetting('weight', '', 'min_state');
										setSetting('weight', '', 'max_state');
									}
								}}
							/>
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<TextField
								id="outlined-multiline-static"
								error={weightRange.max_state === 'error'}
								helperText={
									weightRange.max_state === 'error' &&
									'Max Value should be greater than Min value'
								}
								label="MAX"
								placeholder="MAX"
								fullWidth
								type="number"
								variant="outlined"
								value={weightRange.max}
								onChange={(e) => {
									setSetting(
										'weight',
										e.target.value !== '' ? parseInt(e.target.value) : '',
										'max'
									);
									if (parseInt(e.target.value) <= weightRange.min) {
										setSetting('weight', 'error', 'max_state');
									} else {
										setSetting('weight', '', 'max_state');
										setSetting('weight', '', 'min_state');
									}
								}}
							/>
						</GridItem>
					</GridContainer>
				</TabPanel>
				<TabPanel value={value} index={8}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={12}
							md={12}
							className={classes.paddingContainer}
						>
							<label>
								<b>Select Invoice Value</b>
							</label>
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<TextField
								id="outlined-multiline-static"
								label="MIN"
								placeholder="MIN"
								fullWidth
								helperText={
									invoiceValue.min_state === 'error' &&
									'Min value should be less than Max value'
								}
								error={invoiceValue.min_state === 'error'}
								type="number"
								variant="outlined"
								value={invoiceValue.min}
								onChange={(e) => {
									setSetting(
										'invoice',
										e.target.value !== '' ? parseInt(e.target.value) : '',
										'min'
									);
									if (invoiceValue.max < parseInt(e.target.value)) {
										setSetting('invoice', 'error', 'min_state');
									} else {
										setSetting('invoice', '', 'min_state');
										setSetting('invoice', '', 'max_state');
									}
								}}
							/>
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<TextField
								id="outlined-multiline-static"
								error={invoiceValue.max_state === 'error'}
								helperText={
									invoiceValue.max_state === 'error' &&
									'Max Value should be greater than Min value'
								}
								label="MAX"
								placeholder="MAX"
								fullWidth
								type="number"
								variant="outlined"
								value={invoiceValue.max}
								onChange={(e) => {
									setSetting(
										'invoice',
										e.target.value !== '' ? parseInt(e.target.value) : '',
										'max'
									);
									if (invoiceValue.min > parseInt(e.target.value)) {
										setSetting('invoice', 'error', 'max_state');
									} else {
										setSetting('invoice', '', 'max_state');
										setSetting('invoice', '', 'min_state');
									}
								}}
							/>
						</GridItem>
					</GridContainer>
				</TabPanel>
			</GridItem>
		</GridContainer>
		// </div>
	);
}
