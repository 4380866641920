import { axiosInstance } from './apiInstance';
import { setUser } from 'actions/user';
import get from 'lodash/get';
import { reloadUser } from 'utils/functions';
import * as urls from './urls';

export const getPickupList = (pageNo, filterVals) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getPickupList, {
				params: {
					page: pageNo || undefined,
					...filterVals,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getPickupAvailability = (warehouseId, shipmentMode) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getPickupAvailability, {
				params: {
					warehouse_id: warehouseId,
					shipment_mode: shipmentMode,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getUpdatedPickup = (pickupID) => {
	return (dispatch) => {
		return axiosInstance
			.get(`/pickup/${pickupID}`)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const createPickupRequestAsync = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.createPickup, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const editPickupRequest = (
	obj,
	pickupId,
	update_pickup_manage = false
) => {
	let body = JSON.stringify(obj);
	const url = update_pickup_manage
		? `/pickup/${pickupId}/manage/update/`
		: `/pickup/${pickupId}/`;
	return (dispatch) => {
		return axiosInstance
			.put(url, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const deletePickup = (id) => {
	return (dispatch) => {
		return axiosInstance
			.delete(`/pickup/${id}`)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'delete',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 204) {
					return true;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const pickupReportDownload = (filterVals) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.downloadPickupReport, {
				params: {
					...filterVals,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

// Order-Pickup

export const orderPickupAssociation = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.createPickupOrderAssociation, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
