import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';

const tableStyles = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
	},
	cardAddButton: {
		textAlign: 'right',
		marginLeft: '8px',
	},
	cardActions: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	actionBtnContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		'@media (max-width: 480px)': {
			flexDirection: 'column',
		},
	},
	noMargin: {
		margin: 0,
	},
	error: {
		color: 'blue',
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	mTableFooterRoot: {
		width: 'fit-content',
		float: 'right',
	},
	tablePageNum: {
		float: 'right',
		padding: '14px 16px',
		color: 'rgba(0, 0, 0, 0.54)',
		fontWeight: 500,
	},
	modalText: {
		overflowWrap: 'break-word',
	},
};

export default tableStyles;
