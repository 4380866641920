import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// import Button from '@material-ui/core/Button';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { useDropzone } from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from 'react-i18next';

function UploadFile(props) {
	const [fileName, setFileName] = useState('');
	// const [uniqueName, setUniqueName] = useState('');
	// const [fileType, setFileType] = useState('');
	// const [file, setFile] = useState('');
	// const [isDisabled, setIsDisabled] = useState(true);
	const [isFileUploadDisabled, setIsFileUploadDisabled] = useState(false);
	// const [showUploadLoader, setShowUploadLoader] = useState(false);
	const [borderColor, setBorderColor] = useState('#bbb');
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const { getRootProps, getInputProps } = useDropzone({
		multiple: false,
		accept: props.acceptedFiles,
		onDrop: (files) => dropFile(files),
		onDropAccepted: (e) => handleFileSuccess(e),
		onDropRejected: (e) => handleFileError(e),
	});
	const { ref } = getRootProps();

	useEffect(() => {
		if (props.resetFileNames) {
			setFileName('');
		}
	}, [props.resetFileNames]);

	useEffect(() => {
		if (props.fileName) {
			setFileName(props.fileName);
		}
	}, [props.fileName]);

	useEffect(() => {
		if (props.uploadState === 'error') {
			setBorderColor('red');
		} else if (props.uploadState === 'success') {
			setBorderColor('green');
		} else {
			setBorderColor('');
		}
	}, [props.uploadState]);

	const handleFileSuccess = () => {
		setBorderColor('green');
		enqueueSnackbar(t('file_upload_successful'), {
			variant: 'success',
			autoHideDuration: 3000,
		});
	};

	const handleFileError = (err) => {
		setBorderColor('red');
		// setIsDisabled(true);
		let error = err[0].errors[0].message;
		enqueueSnackbar(error, {
			variant: 'error',
			autoHideDuration: 3000,
		});
	};

	const dropFile = (file) => {
		let droppedFile = file[0];
		if (droppedFile) {
			setBorderColor('green');
			// setIsDisabled(false);
			let name = droppedFile.name;
			setFileName(name);
			props.setFileName && props.setFileName(name);
			let uniqueName = uuidv4(name);
			let fileType = droppedFile.name.split('.').slice(-1)[0];
			// setUniqueName(uniqueName);
			// setFileType(fileType);
			// setFile(droppedFile);
			uploadFile(uniqueName, fileType, droppedFile);
		}
	};

	const uploadFile = (uniqueName, fileType, droppedFile) => {
		// setIsDisabled(true);
		setIsFileUploadDisabled(true);
		// setShowUploadLoader(true);
		setBorderColor('#bbb');
		props
			.generateSignedUrl(uniqueName, fileType, props.url)
			.then((res) => {
				let { url, fields } = res;
				props
					.uploadFiletoS3(droppedFile, url, fields)
					.then((status) => {
						if (status) {
							// setShowUploadLoader(false);
							setIsFileUploadDisabled(false);
							props.uploadFunction(fields.key);
						}
					})
					.catch(() => {
						// setShowUploadLoader(false);
						setIsFileUploadDisabled(false);
						enqueueSnackbar(t('something_went_wrong'), {
							variant: 'error',
							autoHideDuration: 3000,
						});
					});
			})
			.catch(() => {
				// setShowUploadLoader(false);
				setIsFileUploadDisabled(false);
				enqueueSnackbar(t('something_went_wrong'), {
					variant: 'error',
					autoHideDuration: 3000,
				});
			});
	};

	const styles = {
		uploadBtnContainer: {
			display: 'flex',
			padding: '4px 0',
			flexDirection: 'row',
		},
		uploadBoxContent: {
			display: 'flex',
			placeItems: 'center',
			padding: '12px',
			flexDirection: 'column',
			borderRadius: '8px',
			border: `1px dashed ${borderColor}`,
			cursor: 'pointer',
			maxWidth: '300px',
		},
		inputfile: {
			width: '0.1px',
			height: '0.1px',
			opacity: '0',
			overflow: 'hidden',
			position: 'absolute',
			zIndex: -1,
		},
		headingContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
		},
		heading: {
			fontSize: '14px',
		},
		secondaryHeading: {
			fontSize: '14px',
		},
		accordionContainer: {
			marginTop: '16px',
		},
		downloadLink: {
			fontSize: '12px',
			padding: '8px',
		},
		btnContainer: {
			margin: '8px',
			alignSelf: 'center',
		},
		helperText: {
			margin: '4px 0',
		},
		boxMsg: {
			textAlign: 'center',
			fontSize: '14px',
			padding: '0',
			margin: '0',
		},
		'@media (max-width: 600px)': {
			uploadBtnContainer: {
				flexDirection: 'column',
			},
		},
	};

	const useStyles = makeStyles(styles);
	const classes = useStyles();
	return (
		<React.Fragment>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<div className={classes.uploadBtnContainer}>
						<section className={classes.uploadBtnContainer}>
							<RootRef rootRef={ref}>
								<div {...getRootProps({ className: 'dropzone' })}>
									<input {...getInputProps()} disabled={isFileUploadDisabled} />
									<div>
										{fileName ? (
											<div className={classes.uploadBoxContent}>
												{props.showIcon ? (
													<CloudUploadIcon style={{ fontSize: 48 }} />
												) : (
													''
												)}
												<p className={classes.boxMsg}>{fileName}</p>
											</div>
										) : (
											<div className={classes.uploadBoxContent}>
												{props.showIcon ? (
													<CloudUploadIcon style={{ fontSize: 48 }} />
												) : (
													''
												)}
												<p className={classes.boxMsg}>
													{t('upload')} {props.fileType}
												</p>
												{/* <small className={classes.helperText}>
                            Accepted files: <b>{props.acceptedFiles}</b>
                          </small> */}
											</div>
										)}
									</div>
								</div>
							</RootRef>
							<div className={classes.btnContainer}>
								{/* <Button
									size="small"
									color="primary"
									variant="outlined"
									disabled={isDisabled}
									onClick={uploadFile}
								>
									{showUploadLoader ? (
										<CircularProgress color="inherit" size={20} />
									) : (
										'Upload'
									)}
								</Button> */}
							</div>
						</section>
					</div>
				</GridItem>
			</GridContainer>
		</React.Fragment>
	);
}

export default UploadFile;
