import { SET_BACKDROP } from 'actions/appEngine';

const ACTION_HANDLERS = {
	[SET_BACKDROP]: (state, action) => ({
		...state,
		globalBackdrop: action.payload,
	}),
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};

export default function appEngineReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
