import React from 'react';
// react component for creating dynamic tables
import MaterialTable, { MTablePagination } from 'material-table';
// @material-ui/core components
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// import Assignment from '@material-ui/icons/Assignment';
// import Dvr from "@material-ui/icons/Dvr";
import Add from '@material-ui/icons/Add';
// import Favorite from "@material-ui/icons/Favorite";
// core components
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
// import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
// import notificationStyles from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js';
import tableStyles from 'assets/jss/material-dashboard-pro-react/modules/commonTableStyles';
import { useTranslation } from 'react-i18next';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Switch from '@material-ui/core/Switch';

const styles = {
	...tableStyles,
};

// const Transition = React.forwardRef(function Transition(props, ref) {
// 	return <Slide direction="down" ref={ref} {...props} />;
// });

const useStyles = makeStyles(styles);
// const useNotificationStyles = makeStyles(notificationStyles);
const tableRef = React.createRef();

export default function RbSelect(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [loader] = React.useState(false);
	const [data, setData] = React.useState([]);
	const { rules_data } = props;
	// const swapElements = (array, index1, index2) => {
	// 	data[index1] = data.splice(index2, 1, data[index1])[0];
	// 	return data;
	// };
	React.useEffect(() => {
		let prepare_data = rules_data.map((res) => {
			const {
				shipment_type,
				mode,
				// rule_type,
				min_invoice_value,
				max_invoice_value,
				min_weight,
				max_weight,
				destination_pincodes,
				pickup_pincodes,
				from_zone,
				to_zone,
				is_appointment_taken,
			} = res;
			let criteria = {};
			// let _empty = Object.values(new_obj).every((value) => value === '');
			if (shipment_type && !shipment_type.every((val) => val === '')) {
				criteria['order_type'] = shipment_type;
			}
			if (mode && !mode.every((val) => val === '')) {
				criteria['mode'] = mode;
			}
			if (
				destination_pincodes &&
				!destination_pincodes.every((val) => val === '')
			) {
				criteria['destination_pincode'] = destination_pincodes;
			}

			if (pickup_pincodes && !pickup_pincodes.every((val) => val === '')) {
				criteria['source_pincode'] = pickup_pincodes;
			}
			if (from_zone && !from_zone.every((val) => val === '')) {
				criteria['from_zone'] = from_zone;
			}
			if (to_zone && !to_zone.every((val) => val === '')) {
				criteria['to_zone'] = to_zone;
			}

			if (min_invoice_value || max_invoice_value) {
				criteria['invoice'] = {
					min: min_invoice_value,
					max: max_invoice_value,
				};
			}
			if (min_weight || max_weight) {
				criteria['weight'] = { min: min_weight, max: max_weight };
			}
			if (is_appointment_taken) {
				criteria['is_appointment_taken'] = is_appointment_taken;
			}
			return {
				...res,
				criteria,
			};
		});
		setData(prepare_data);
	}, [rules_data]);

	const change_priority = (newindex, oldindex, data) => {
		setData((prevState) => {
			const dataCopy = [...prevState];
			dataCopy[oldindex] = dataCopy.splice(newindex, 1, dataCopy[oldindex])[0];
			let body = {
				carrier_setting_id: data.carrier_setting_id
					? data.carrier_setting_id
					: 0,
				priority: dataCopy.map((val) => {
					return val.id;
				}),
			};
			props
				.updateRule(body)
				.then(() => {
					props.setBackdrop(false);
					return dataCopy;
				})
				.catch((err) => {
					props.setBackdrop(false);
					console.log(err);
					return prevState;
				});
			return dataCopy;
		});
	};

	const handleChange = (data) => {
		props.setBackdrop(true);
		let body = {
			is_active: !data.is_active,
			rb_select_rule_id: data.id ? data.id : 0,
		};
		props
			.updateRule(body)
			.then(() => {
				props.setBackdrop(false);
				setData((prevState) => {
					let temp = [...prevState];
					temp[data.tableData.id].is_active = !data.is_active;
					return temp;
				});
			})
			.catch((err) => {
				props.setBackdrop(false);
				console.log(err);
			});
	};
	return (
		<>
			<Card>
				<CardHeader color="primary" icon>
					<h5 className={classes.cardIconTitle}>{t('rb_select')}</h5>
				</CardHeader>
				<CardBody>
					<div className={classes.cardAddButton}>
						<Link to="/client/create-rules">
							<Button color="primary">
								<Add />
								{t('create_new_rule')}
							</Button>
						</Link>
					</div>
				</CardBody>
				<MaterialTable
					localization={{
						header: {
							actions: t('actions'),
						},
					}}
					tableRef={tableRef}
					columns={[
						{
							title: t('priority_label'),
							render: (r_data) => {
								return (
									<div>
										{r_data.tableData.id > 0 && (
											<ArrowUpwardIcon
												style={{ color: '#6F57E9', cursor: 'pointer' }}
												onClick={() => {
													change_priority(
														r_data.tableData.id - 1,
														r_data.tableData.id,
														r_data
													);
												}}
											/>
										)}
										{data.length - 1 !== r_data.tableData.id && (
											<ArrowDownwardIcon
												style={{ cursor: 'pointer' }}
												onClick={() => {
													change_priority(
														r_data.tableData.id + 1,
														r_data.tableData.id,
														r_data
													);
												}}
											/>
										)}
									</div>
								);
							},
							sorting: false,
							searchable: false,
						},
						{
							title: t('rule_name'),
							field: 'rule_name',
							sorting: false,
							searchable: false,
						},
						{
							title: t('added_criteria'),
							field: 'criteria',
							render: (data) => {
								return (
									<span>
										{Object.keys(data.criteria).map((criteria, i, ary) => {
											return (
												<>
													{t(criteria)}
													{ary.length - 1 !== i && '/'}
												</>
											);
										})}
									</span>
								);
							},
							sorting: false,
							searchable: false,
						},
						{
							title: t('courier'),
							field: 'contact',
							sorting: false,
							searchable: false,
						},
						{
							title: t('active'),
							field: 'contact',
							render: (data) => {
								return (
									<>
										<Switch
											checked={data.is_active}
											onChange={() => handleChange(data)}
											name="checkedA"
											inputProps={{ 'aria-label': 'secondary checkbox' }}
											color="primary"
										/>
									</>
								);
							},
							sorting: false,
							searchable: false,
						},
					]}
					title=""
					options={{
						pageSize: 10,
						filtering: false,
						search: false,
						actionsColumnIndex: -1,
						toolbar: false,
						padding: 'dense',
					}}
					isLoading={loader}
					actions={[
						() => ({
							icon: 'edit',
							tooltip: t('edit_rule'),
							onClick: (event, rowData) => {
								props.setCarrierSettingEditData(rowData);
								props.history.push('/client/edit-rule/' + rowData.rule_name);
								props.setCarrierSettingId(rowData.carrier_setting_id);
								props.setRulePriority(rowData.priority);
							},
							// hidden: !props.user.permissions.includes('change_clientpoc'),
						}),
						() => ({
							icon: 'delete',
							tooltip: t('delete_rule'),
							// onClick: (event, rowData) => {
							// 	deleteModalHandler(rowData);
							// },
							// hidden:
							// 	rowData.pocID === signdInClientPocID ||
							// 	!props.user.permissions.includes('delete_clientpoc'),
						}),
					]}
					data={data}
					components={{
						Pagination: (propsNew) => {
							return (
								<React.Fragment>
									<MTablePagination
										{...propsNew}
										classes={{
											root:
												propsNew.classes.root + ' ' + classes.mTableFooterRoot,
										}}
									/>
									<div className={classes.tablePageNum}>
										{/* Page: {currentPageNo} */}
									</div>
								</React.Fragment>
							);
						},
					}}
				/>
			</Card>
		</>
	);
}
