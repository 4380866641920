import {
	SET_WAREHOUSE_LIST,
	SET_WAREHOUSE_EDIT_STATUS,
} from '../../actions/warehouse';

const ACTION_HANDLERS = {
	[SET_WAREHOUSE_LIST]: (state, action) => ({
		...state,
		warehouseList: action.payload,
	}),
	[SET_WAREHOUSE_EDIT_STATUS]: (state, action) => {
		return {
			...state,
			warehouseEditStatus: action.payload.status,
			warehouseEditData: action.payload.data,
		};
	},
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};

export default function warehouseReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
