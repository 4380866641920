import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardText from 'components/Card/CardText.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { readCookie, scrollToElement } from 'utils/functions';
import { useTranslation } from 'react-i18next';
import extededFormStyles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js';
import MaterialTable from 'material-table';
import { FormControlLabel, Switch } from '@material-ui/core';
import ClientSearchComponent from 'components/ClientSearch/index.js';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';

const styles = {
	extededFormStyles,
	cardCategoryWhite: {
		color: 'rgba(255,255,255,.62)',
		margin: '0',
		fontSize: '14px',
		marginTop: '0',
		marginBottom: '0',
	},
	cardTitleWhite: {
		color: '#FFFFFF',
		marginTop: '0px',
		minHeight: 'auto',
		fontWeight: '300',
		fontFamily: 'inherit',
		marginBottom: '3px',
		textDecoration: 'none',
	},
	alignCenter: {
		textAlign: 'center',
	},
	deliveryPartner: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	dpImage: {
		width: '48px',
		height: '48px',
		marginRight: '16px',
		borderRadius: '4px',
	},
};

const useStyles = makeStyles(styles);
const useExtendedFormStyles = makeStyles(extededFormStyles);
const tableRef = React.createRef();

export default function DisableCarriers(props) {
	const classes = useStyles();
	const extendedClasses = useExtendedFormStyles();
	const [userCarriers, setUserCarriers] = useState([]);
	const [isStaff, setIsStaff] = useState(false);
	const [clientValue, setClientValue] = useState({});

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		scrollToElement('#app-header');
		let bearerToken = readCookie('bearer');
		if (bearerToken) {
			let client = readCookie('client', true);
			if (client) {
				fetchUserCarrers();
				setIsStaff(client.is_staff);
			}
		} else {
			props.setUser({ reAuth: true, showReAuthModal: true });
		}
	}, []);

	const userCarrierToggle = (val, id) => {
		if (clientValue.length == 0) {
			if (isStaff && Object.keys(clientValue).length == 0) {
				enqueueSnackbar('Please select Client', {
					variant: 'error',
					autoHideDuration: 5000,
				});
				return;
			}
		}
		props.setBackdrop(true);
		let client = readCookie('client', true);
		let client_id = isStaff ? clientValue.id : client?.client_poc?.client?.id;
		createCarrierDisable(id, client_id, val);
	};

	const createCarrierDisable = (id, client, flag) => {
		if (client) {
			props
				.createDisableCarrier({
					delivery_partner_id: id,
					client_id: client,
					status: flag,
				})
				.then(() => {
					enqueueSnackbar('Success', {
						variant: 'success',
						autoHideDuration: 500,
					});
					fetchUserCarrers();
				});
		}
	};

	const fetchUserCarrers = () => {
		props.setBackdrop(true);
		props.getUserCarriers(clientValue.id).then((data) => {
			setUserCarriers(data);
			props.setBackdrop(false);
		});
	};

	useEffect(() => {
		if (clientValue.length !== 0) {
			fetchUserCarrers();
		}
	}, [clientValue]);

	const setClientID = (event, val) => {
		if (!isEmpty(val)) {
			// setSelectedClient(val.id);
			setClientValue(val);
		} else {
			// setSelectedClient('');
			setClientValue({});
		}
	};

	return (
		<div>
			<GridContainer spacing={2}>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="primary" text>
							<CardText color="primary">
								<h4 className={classes.cardTitle}>{t('carrier_partners')}</h4>
							</CardText>
						</CardHeader>
						<CardBody></CardBody>
						{isStaff && (
							<GridContainer>
								<GridItem xs={8} sm={8} md={8}></GridItem>

								<GridItem xs={4} sm={4} md={4}>
									<ClientSearchComponent
										value={clientValue}
										onChange={setClientID}
										showClient_id={true}
										// clientState={clientState}
										isStaff={isStaff}
									/>
								</GridItem>
							</GridContainer>
						)}
						<MaterialTable
							localization={{
								header: {
									actions: t('active'),
								},
							}}
							tableRef={tableRef}
							columns={[
								{
									title: t('carrier_name'),
									field: 'name',
									sorting: false,
									searchable: false,
								},
								{
									title: t('carrier_logo'),
									field: 'logo',
									render: (data) => {
										return (
											<div className={classes.deliveryPartner}>
												<img
													className={classes.dpImage}
													alt={data.logo}
													src={data.logo}
												/>
											</div>
										);
									},
									sorting: false,
									searchable: false,
								},
								{
									title: t('active'),
									field: 'active',
									sorting: false,
									searchable: false,
									render: (data) => (
										<FormControlLabel
											control={
												<Switch
													checked={!data.is_disable}
													onChange={(event) =>
														userCarrierToggle(event.target.checked, data.id)
													}
													classes={{
														switchBase: extendedClasses.switchBase,
														checked: extendedClasses.switchChecked,
														thumb: extendedClasses.switchIcon,
														track: extendedClasses.switchBar,
													}}
													name="is_disable"
													color="primary"
												/>
											}
										/>
									),
								},
							]}
							title=""
							options={{
								pageSize: 10,
								filtering: false,
								search: false,
								actionsColumnIndex: -1,
								toolbar: false,
								padding: 'dense',
							}}
							isLoading={false}
							data={userCarriers}
						/>
					</Card>
				</GridItem>
			</GridContainer>
		</div>
	);
}
