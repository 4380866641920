export const SET_SHIPMENT_LIST = 'SET_SHIPMENT_LIST';
export const SET_SHIPMENT_EDIT_STATUS = 'SET_SHIPMENT_EDIT_STATUS';
export const SET_RECHARGE_FOR_SHIPMENT = 'SET_RECHARGE_FOR_SHIPMENT';
export const SET_REPLAY_REQUEST = 'SET_REPLAY_REQUEST';
export const SET_ADDON_EMAIL = 'SET_ADDON_EMAIL';

export const setShipments = (payload) => {
	return {
		type: SET_SHIPMENT_LIST,
		payload,
	};
};

export const setShipmentEditStatus = (payload) => {
	return {
		type: SET_SHIPMENT_EDIT_STATUS,
		payload,
	};
};

export const setShipmentRechargeData = (payload) => {
	return {
		type: SET_RECHARGE_FOR_SHIPMENT,
		payload,
	};
};

export const setReplayRequest = (payload) => {
	return {
		type: SET_REPLAY_REQUEST,
		payload,
	};
};
export const setAddOnEmail = (payload) => {
	return {
		type: SET_ADDON_EMAIL,
		payload,
	};
};
