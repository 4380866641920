import axios from 'axios';
import { api } from 'config/variables';
import { readCookie } from 'utils/functions';
import { setTerm, setCarrierSettingPopup } from 'actions/user';
import { store } from '../index';
import { generateLogger } from 'apilist';

var oauth_client_id;
switch (api.currentEnv) {
	case 'production':
		oauth_client_id = process.env.REACT_APP_PROD_CLIENT_ID;
		break;
	case 'dev':
		oauth_client_id = process.env.REACT_APP_LOCAL_CLIENT_ID;
		break;
	default:
		oauth_client_id = process.env.REACT_APP_STAGING_CLIENT_ID;
		break;
}

export { oauth_client_id };

export const axiosInstance = axios.create({
	baseURL: api.baseURL + api.restApiRoot,
	headers: {
		'Content-Type': 'application/json;charset=UTF-8',
	},
	crossDomain: true,
	withCredentials: true,
});

export const axiosRatingInstance = axios.create({
	baseURL:
		api.other_services.rating.baseURL + api.other_services.rating.restApiRoot,
});

axiosInstance.interceptors.request.use(
	function (config) {
		let token = readCookie('bearer');
		if (token) {
			config.headers['Authorization'] = token && `token ${token}`;
		} else {
			// config.headers['Content-Type'] = 'application/json'
		}
		return config;
	},
	function (err) {
		return Promise.reject(err);
	}
);

axiosInstance.interceptors.response.use(
	function (response) {
		store.dispatch(
			setTerm({
				maintenance_popup: false,
			})
		);
		return response;
	},
	function (err) {
		if (err?.response?.status > 500) {
			store.dispatch(
				setTerm({
					maintenance_popup: true,
				})
			);
		}
		return Promise.reject(err);
	}
);

axiosInstance.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		store.dispatch(
			setTerm({
				agreement_popup: false,
			})
		);

		return response;
	},
	function (err) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		if (
			err?.response?.status == 403 &&
			err?.response?.data?.detail ==
				'Please accept agreement terms to proceed further'
		) {
			store.dispatch(
				setTerm({
					agreement_popup: true,
					agreement_popup_data: err.response.data,
				})
			);
			return Promise.reject();
		}
		if (
			err?.response?.status == 403 &&
			err?.response?.data?.detail == 'Auto Assign Carrier is required'
		) {
			localStorage.setItem('client_id', err?.response?.data?.client_id);
			store.dispatch(
				setCarrierSettingPopup({
					carrier_setting_required: true,
				})
			);

			return Promise.reject();
		}
		const err_obj = { url: err?.response?.request?.responseURL, error: err };
		if (!err_obj?.url?.includes('add-logs')) {
			store.dispatch(generateLogger(err_obj));
		}
		return Promise.reject(err);
	}
);

axiosRatingInstance.interceptors.request.use(
	function (config) {
		let token = readCookie('bearer');
		config.headers['Content-Type'] = 'application/json';
		if (token) {
			config.headers['Authorization'] = token && `token ${token}`;
		} else {
			// config.headers['Content-Type'] = 'application/json'
		}
		return config;
	},
	function (err) {
		return Promise.reject(err);
	}
);

axiosRatingInstance.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data

		return response;
	},
	function (err) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		return Promise.reject(err);
	}
);
