import { axiosInstance } from './apiInstance';
import { setWarehouseList } from 'actions/warehouse';
import { setUser } from 'actions/user';
import get from 'lodash/get';
import { reloadUser } from 'utils/functions';
import * as urls from './urls';

export const getWarehouseList = (pageNo, filterVals, query, fetchAll) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getWarehouseList, {
				params: {
					page: pageNo || undefined,
					all_records: fetchAll || undefined,
					q: query || undefined,
					...filterVals,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					dispatch(setWarehouseList(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const addWarehouseAsync = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.createWarehouse, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getStates = () => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getStateList)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const editWarehouse = (obj, warehouseId) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.put(`/warehouses/${warehouseId}/`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const deleteWarehouse = (id) => {
	return (dispatch) => {
		return axiosInstance
			.delete(`/warehouses/${id}`)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'delete',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 204) {
					return true;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getMostUsedWarehouseList = (pageNo, filterVals) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getTopWarehouseList, {
				params: {
					page: pageNo || 1,
					...filterVals,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					dispatch(setWarehouseList(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getAddressByPincode = (pincode) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getAddressByPincode, {
				params: {
					pincode: pincode,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					dispatch(setWarehouseList(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
