import {
	RULE_EDIT_DATA,
	CARRIER_SETTING_ID,
	SET_RULE_PRIORITY,
} from 'actions/carrierSettings';

const ACTION_HANDLERS = {
	[RULE_EDIT_DATA]: (state, action) => {
		return {
			...state,
			rule_edit_data: action.payload,
		};
	},
	[CARRIER_SETTING_ID]: (state, action) => {
		return {
			...state,
			carrier_setting_id: action.payload,
		};
	},
	[SET_RULE_PRIORITY]: (state, action) => {
		return {
			...state,
			rule_priority: action.payload,
		};
	},
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	rule_edit_data: {},
	carrier_setting_id: '',
	rule_priority: '',
};

export default function carrierSettingReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
