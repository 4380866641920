import React, { useState, useEffect } from 'react';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateUserTnCStatus } from 'apilist';
import { set403Error } from 'actions/user';
import { readCookie } from 'utils/functions';
import { useTranslation } from 'react-i18next';
import { api } from 'config/variables';

function Error403(props) {
	const [tosDialog, setTosDialog] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setTosDialog(props.user.error403);
	}, [props.user.error403]);

	const handleClick = (type) => {
		props.set403Error({
			error403: false,
		});
		let source = readCookie('source');
		if (source == 'sr' || !source || source == 'null') {
			switch (type) {
				case 'kyc':
					return window.location.replace(`${api.sso_url}/new-kyc`);
				case 'session':
					return window.location.replace(`${api.sso_url}/logout?source=rb`);
				case 'account_not_active':
					return window.location.replace(`${api.sso_url}/dashboard`);
				case 'redirect':
					return window.location.replace(
						`${api.sso_pickr_url}/sign-in?source=rb`
					);

				default:
					return window.location.replace(`${api.sso_url}/login?source=rb`);
			}
		}
		if (source == 'pr') {
			switch (type) {
				case 'kyc':
					return window.location.replace(`${api.sso_pickr_url}/dashboard`);
				case 'session':
					return window.location.replace(
						`${api.sso_pickr_url}/dashboard?source=rb`
					);
				case 'account_not_active':
					return window.location.replace(`${api.sso_pickr_url}/dashboard`);
				case 'redirect':
					return window.location.replace(`${api.sso_url}/login?source=rb`);

				default:
					return window.location.replace(
						`${api.sso_pickr_url}/sign-in?source=rb`
					);
			}
		}
	};
	return (
		<Dialog
			open={tosDialog}
			// fullWidth
			disableBackdropClick
			maxWidth="sm"
			onClose={() => setTosDialog(false)}
			aria-labelledby="tos-title"
			aria-describedby="tos-description"
		>
			<DialogContent>
				<DialogContentText id="tos-description">
					{props?.user?.error403Data?.errMsg}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => handleClick(props?.user?.error403Data?.type)}
					color="primary"
					// disabled={!tosCheckbox}
				>
					{t('continue_label')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const mapDispatchToProps = {
	updateUserTnCStatus,
	set403Error,
};

const mapStateToProps = (state) => ({
	appEngine: state.appEngine,
	user: state.user,
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Error403)
);
