import { axiosInstance } from './apiInstance';
import { setDPAccountList } from 'actions/dp_account';
import { setUser } from 'actions/user';
import { reloadUser } from 'utils/functions';
import * as urls from './urls';

export const getDPAccountList = (pageNo) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getDPAccountList, {
				params: {
					page: pageNo || undefined,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					dispatch(setDPAccountList(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const updateDPAccount = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.put(`/dp/dp_accounts/${obj.id}/`, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const addDPAccount = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.addDPAccount, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getDPFields = (delivery_partner_id, mode_id) => {
	return (dispatch) => {
		return axiosInstance
			.get(`/dp/dpfields/${delivery_partner_id}/${mode_id}/`)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data.results;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
