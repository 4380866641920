import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardText from 'components/Card/CardText.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { readCookie } from 'utils/functions';
import { useTranslation } from 'react-i18next';
import extededFormStyles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js';
import { useSnackbar } from 'notistack';
import isEmpty from 'lodash/isEmpty';

// import { Typography } from '@material-ui/core';
import custom_icon from 'assets/img/custom.svg';
import none_icon from 'assets/img/none_rule.svg';
import cheapest_icon from 'assets/img/cheapest_carrier_rule.svg';
import DragDrop from './DragDrop';
import RbSelect from './RbSelect';
import ClientSearchComponent from 'components/ClientSearch/index.js';
// import { Button } from '@material-ui/core';
import Button from 'components/CustomButtons/Button.js';

import tickmark from './tickmark.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// import MuiDialogActions from '@material-ui/core/DialogActions';

const styles = {
	extededFormStyles,
	cardCategoryWhite: {
		color: 'rgba(255,255,255,.62)',
		margin: '0',
		fontSize: '14px',
		marginTop: '0',
		marginBottom: '0',
	},
	cardTitleWhite: {
		color: '#FFFFFF',
		marginTop: '0px',
		minHeight: 'auto',
		fontWeight: '300',
		fontFamily: 'inherit',
		marginBottom: '3px',
		textDecoration: 'none',
	},
	alignCenter: {
		textAlign: 'center',
	},
	custom_card: {
		// textAlign: 'center',
		minHeight: '180px',
	},
	deliveryPartner: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	dpImage: {
		width: '48px',
		height: '48px',
		marginRight: '16px',
		borderRadius: '4px',
	},
	selectRule: {
		// width: '50%',
		margin: '25px auto',
	},
	selectCardBorder: {
		border: '2px solid #6F57E9',
	},
	dradDropCard: {
		height: '200px',
		width: '250px',
		margin: '10px auto',
	},
	selectCard: {
		padding: '15px',
		paddingBottom: '25px',
		boxShadow: '4px 6px 10px 0 rgba(209,209,209,1)',
	},
};

const useStyles = makeStyles(styles);

const selectCardsData = [
	{
		id: 1,
		name: 'none',
		img: none_icon,
		message: 'auto_assign_carrier_is_disabled',
	},
	{
		id: 2,
		name: 'cheapest',
		img: cheapest_icon,
		value: 'cheapest_first',
		message: 'carrier_will_be_assigned_based_on_cheapest_cost',
	},
	{
		id: 3,
		name: 'custom',
		value: 'custom',
		img: custom_icon,
		message: 'carrier_will_be_assigned_based_on_you_set_priority',
	},
	{
		id: 4,
		name: 'SmartShip Priority Engine',
		value: 'rocketbox_select',
		img: custom_icon,
		message: 'carrier_will_be_assigned_based_on_you_set_priority',
	},
];

export default function SetRules(props) {
	const classes = useStyles();
	const [autoAssignData, setAutoAssignData] = useState({});
	const [client, setClient] = useState({});
	const [modes, setModes] = useState([]);
	const [clientValue, setClientValue] = useState({});
	const [isStaff, setIsStaff] = useState(false);
	const [rules_data, setRulesData] = useState([]);
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	// const [clientState, setClientState] = useState('');

	const [selectedCard, setSelectedCard] = useState(0);
	const [partiallyselectedCard, setPartiallySelectedCard] = useState(0);
	const [edit, setEdit] = useState(false);
	const [item, setItem] = useState([]);
	const [partialCardValue, setPartialCardValue] = useState();

	const { t } = useTranslation();

	useEffect(() => {
		// scrollToElement('#app-header');
		let bearerToken = readCookie('bearer');
		fetchAutoAssignCarriers();
		props.getAllModeLists().then((data) => {
			props.setBackdrop(true);
			setModes(data);
			props.setBackdrop(false);
		});
		if (bearerToken) {
			let client = readCookie('client', true);
			if (client) {
				setClient(client);
				setIsStaff(client.is_staff);
				fetchAutoAssignCarriers();
				props.getAllModeLists().then((data) => {
					props.setBackdrop(true);
					setModes(data);
					props.setBackdrop(false);
				});
			}
		} else {
			props.setUser({ reAuth: true, showReAuthModal: true });
		}
		window.addEventListener('popstate', (e) => {
			e.preventDefault();
		});
		// window.onbeforeunload = function () {
		// 	return 'Your work will be lost.';
		// };

		// window.addEventListener('onbeforeunload', function () {
		// 	alert('call');
		// });
	}, []);

	const selectCard = (val) => {
		// if (isStaff && Object.keys(clientValue).length == 0) {
		// 	enqueueSnackbar('Please select Client', {
		// 		variant: 'error',
		// 		autoHideDuration: 5000,
		// 	});
		// 	return;
		// }
		// if (selectedCard == val) {
		// 	return;
		// }
		// setSelectedCard(val);
		setPartialCardValue(val);
		if (selectedCard == 2 && edit) {
			setOpen(true);
		} else {
			setPartiallySelectedCard(val);
		}
	};

	const changeSelected = (val) => {
		// if (val == 3) {
		// 	setSelectedCard(3);
		// 	return;
		// }
		if (isStaff && Object.keys(clientValue).length == 0) {
			enqueueSnackbar('Please select Client', {
				variant: 'error',
				autoHideDuration: 5000,
			});
			return;
		}
		if (selectedCard == val) {
			return;
		}
		if (selectedCard != 0 && val == 0) {
			// delete entry in db
			// return;
			// props.setBackdrop(true);
			// props.deleteAutoAssignCarrier(autoAssignData.id).then(() => {
			// 	fetchAutoAssignCarriers();
			// 	props.setBackdrop(false);
			// });
			updateAutoAssignRule(modes, selectedCard, false);
		} else if (selectedCard == 0 && val != 0 && !autoAssignData.id) {
			props.setBackdrop(true);
			const data = prepareBodyData(selectCardsData[val].value, modes);
			props.createAutoAssignCarrier(data).then(() => {
				fetchAutoAssignCarriers();
				props.setBackdrop(false);
				enqueueSnackbar('Success', {
					variant: 'success',
					autoHideDuration: 500,
				});
				props.closeModel && val == 1 && props.closeModel();
				props.closeModel && val == 2 && props.showCloseButton(true);
			});
			// create a entry in db
		} else {
			updateAutoAssignRule(modes, val, true);
		}
		setSelectedCard(val);
	};

	const prepareBodyData = (rule, allModes) => {
		let data = {};
		data.client_id = isStaff
			? clientValue.id
			: client?.client_poc?.client?.id
			? client?.client_poc?.client?.id
			: localStorage.getItem('client_id');
		data.rule_type = rule;
		data.carriers_list = allModes ? allModes : {};
		return data;
	};

	const updateAutoAssignRule = (allModes, val = selectedCard, is_active) => {
		props.setBackdrop(true);
		let data = prepareBodyData(selectCardsData[val].value, allModes);
		data.is_active = is_active;
		props.updateAutoAssignCarrier(data, autoAssignData.id).then(() => {
			fetchAutoAssignCarriers();
			props.setBackdrop(false);
			enqueueSnackbar('Success', {
				variant: 'success',
				autoHideDuration: 500,
			});
			props.closeModel && val == 1 && props.closeModel();
			props.closeModel && val == 2 && props.showCloseButton(true);
		});
	};

	const fetchAutoAssignCarriers = () => {
		props.setBackdrop(true);
		props.getAutoAssignCarrier(clientValue.id).then((data) => {
			if (data.length !== 0) {
				setAutoAssignData(data[0]);
				data[0]?.carriers_list.length !== 0 && setModes(data[0].carriers_list);
				if (data[0].rule_type == 'custom' && data[0].is_active) {
					setSelectedCard(2);
					setPartiallySelectedCard(2);
				} else if (
					data[0].rule_type == 'rocketbox_select' &&
					data[0].is_active
				) {
					setSelectedCard(3);
					setPartiallySelectedCard(3);

					setRulesData(data[0].rb_select_rules);
					props.setCarrierSettingId(data[0].id ? data[0].id : 0);
					props.setRulePriority(data[0].rb_select_rules.length + 1);
				} else if (data[0].rule_type == 'cheapest_first' && data[0].is_active) {
					setSelectedCard(1);
					setPartiallySelectedCard(1);
				}
				// setSelectedCard(3);
			} else {
				setSelectedCard(0);
				setPartiallySelectedCard(0);
			}
			props.setBackdrop(false);
		});
	};

	const setClientID = (event, val) => {
		if (!isEmpty(val)) {
			// setSelectedClient(val.id);
			setClientValue(val);
		} else {
			// setSelectedClient('');
			setClientValue({});
		}
	};

	useEffect(() => {
		if (clientValue.length !== 0) {
			fetchAutoAssignCarriers();
		}
	}, [clientValue]);

	useEffect(() => {
		if (edit) {
			console.log('edit happening');
		} else {
			console.log('else');
		}
	}, [edit]);

	const saveCarrierList = () => {
		if (edit) {
			updateAutoAssignRule(item);
			setEdit(false);
			setOpen(false);
		} else {
			enqueueSnackbar('No Changes Detected', {
				variant: 'warning',
				autoHideDuration: 1500,
			});
		}
	};
	const closeModal = () => {
		setOpen(false);
		setEdit(false);
		setPartiallySelectedCard(partialCardValue);
	};
	// const saveChanges()

	return (
		<div>
			<GridContainer spacing={2}>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="primary" text>
							<CardText color="primary">
								<h4 className={classes.cardTitle}>{t('set_carrier_rules')}</h4>
							</CardText>
						</CardHeader>
						<CardBody>
							{isStaff && (
								<GridContainer>
									<GridItem xs={8} sm={8} md={8}></GridItem>

									<GridItem xs={4} sm={4} md={4}>
										<ClientSearchComponent
											value={clientValue}
											onChange={setClientID}
											showClient_id={true}
											// clientState={clientState}
											isStaff={isStaff}
										/>
									</GridItem>
								</GridContainer>
							)}
							<GridItem xs={12} sm={12} md={12}>
								<GridContainer>
									{selectCardsData.map((item, i) => (
										<GridItem
											xs={3}
											sm={3}
											md={3}
											key={i}
											onClick={() => selectCard(i)}
											className={classes.alignCenter}
										>
											<Card
												className={`${
													partiallyselectedCard == i && classes.selectCardBorder
												} ${classes.selectCard} ${classes.custom_card}`}
											>
												{selectedCard == i && (
													<img
														src={tickmark}
														style={{ 'align-self': 'self-end' }}
													/>
												)}
												<div className={classes.alignCenter}>
													<img src={item.img} width="50" />
												</div>

												<h5>{t(item.name)}</h5>
												<span>{t(item.message)}</span>
											</Card>
										</GridItem>
									))}
								</GridContainer>
							</GridItem>
							{selectedCard !== partiallyselectedCard && (
								<Button
									onClick={() => changeSelected(partiallyselectedCard)}
									style={{ float: 'right' }}
									color="primary"
								>
									Select
								</Button>
							)}
							{selectedCard == 2 && partiallyselectedCard == 2 ? (
								<>
									<GridItem
										sm={10}
										md={10}
										xs={10}
										className={classes.selectRule}
									>
										<DragDrop
											data={modes}
											updateAutoAssignRule={updateAutoAssignRule}
											edit={edit}
											setEdit={(bool) => setEdit(bool)}
											itemData={item}
											setitemData={(data) => {
												setItem(data);
											}}
										/>
									</GridItem>
									<GridItem
										sm={10}
										md={10}
										xs={10}
										className={classes.selectRule}
									>
										<span style={{ float: 'left' }}>
											Note : Prioritization order is left to right.
										</span>
										<Button
											style={{ float: 'right' }}
											onClick={() => {
												saveCarrierList();
											}}
											color="primary"
										>
											Save
										</Button>
									</GridItem>
								</>
							) : selectedCard == 3 ? (
								<GridItem
									sm={12}
									md={12}
									xs={12}
									className={classes.selectRule}
								>
									<RbSelect {...props} rules_data={rules_data} />
								</GridItem>
							) : (
								<></>
							)}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
			<Dialog
				onClose={() => setOpen(false)}
				aria-labelledby="customized-dialog-title"
				open={open}
				fullWidth
			>
				<DialogContent dividers>
					<p>You have unsaved changes in courier prioritization.</p>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={() => closeModal()}>
						Close
					</Button>
					<Button
						color="primary"
						onClick={() => {
							saveCarrierList();
						}}
					>
						Save changes
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
