const partner_to_mode_mapping = {
	Delhivery: [
		{
			mode_id: 'surface',
			mode_name: 'Surface',
		},
	],
	Fedex: [
		{
			mode_id: 'STANDARD_OVERNIGHT',
			mode_name: 'Air (Standard)',
		},
		{
			mode_id: 'PRIORITY_OVERNIGHT',
			mode_name: 'Air (Priority)',
		},
		{
			mode_id: 'FEDEX_EXPRESS_SAVER',
			mode_name: 'Surface',
		},
	],
	Safex: [
		{
			mode_id: 'air',
			mode_name: 'Air',
		},
		{
			mode_id: 'surface',
			mode_name: 'Surface',
		},
	],
	Delex: [
		{
			mode_id: 'air',
			mode_name: 'Air',
		},
		{
			mode_id: 'surface',
			mode_name: 'Surface',
		},
	],
	Spoton: [
		{
			mode_id: 'surface',
			mode_name: 'Surface',
		},
	],
	Xpressbees: [
		{
			mode_id: 'surface',
			mode_name: 'Surface',
		},
	],
	ScorpionExpress: [
		{
			mode_id: 'surface',
			mode_name: 'Surface',
		},
	],
	DelhiveryExpress: [
		{
			mode_id: 'air',
			mode_name: 'Air',
		},
		{
			mode_id: 'surface',
			mode_name: 'Surface',
		},
	],
	Gati: [
		{
			mode_id: 'surface_express',
			mode_name: 'Surface',
		},
	],
};

const delivery_status = [
	'Created',
	'Picked Up',
	'In-Transit',
	'RTO In Transit',
	'Reached At Destination',
	'Out For Delivery',
	'Delivered',
	'RTO Delivered',
	'Cancelled',
	'RTO',
	'Pending',
	'Failed',
	'Undelivered',
	'Unserviceable',
	'Re Assigned',
	'Lost',
	'Damaged',
	'Archived',
];

const rto_status = [
	'RTO',
	'RTO Initiated',
	'RTO In Transit',
	'RTO OFD',
	'RTO Delivered',
	'RTO Locked',
];

const weight_discrepancie_status = [
	'Open',
	'Disputed',
	'Expired',
	'Dispute Closed',
	'Minor Discrepancy',
];

const zone_event = [
	'Add zone',
	'Update Pickup Availability',
	'Update COD Availability',
	'Update Appointment Based Delivery Pincode',
	'Update Time Based Pickup Pincode',
	'Incremental Serviceability',
];

const uploaded_file_status = ['processing', 'success', 'failed', 'partial'];

const to_pay_status = ['NOT RECEIVED', 'RECEIVED', 'PARTIALLY RECEIVED'];
const remit_status = ['Not Paid', 'paid'];

const pickup_status = [
	'Created',
	'Pending',
	'Failed',
	'Done',
	'Rescheduled',
	'Cancelled',
];

const in_transit_status = [
	'Reached At Destination',
	'Out For Delivery',
	'Undelivered',
];

const with_insurance = ['Yes', 'No'];
const is_direct_option = ['Yes', 'No', ''];
const with_rb_advantage = ['Yes', 'No'];

const transaction_type = {
	Cr: 'Credit',
	Dr: 'Debit',
};

const order_status = [
	'Created',
	'Processing',
	'Pickup Generated',
	'Unserviceable',
];

const package_content_map = {
	documents: 'Documents',
	product_commodities: 'Product Commodities',
};

const shipment_purpose_map = {
	commercial: 'Commercial',
	personal: 'Personal',
};

const downloads_map = {
	labelMsg: 'Download label',
	podMsg: 'Download POD',
	delhivery: {
		podNotAvailableMsg: 'Not generated',
		labelNotAvailableMsg: 'Not generated',
	},
	fedex: {
		podNotAvailableMsg: 'Not generated',
		labelNotAvailableMsg: 'Not generated',
	},
	safex: {
		podNotAvailableMsg:
			'POD not available for Safex, contact us if you need POD',
		labelNotAvailableMsg: 'No need to generate label for Safex',
	},
	spoton: {
		podNotAvailableMsg: 'Not generated',
		labelNotAvailableMsg: 'Not generated',
	},
	delex: {
		podNotAvailableMsg: 'Not available',
		labelNotAvailableMsg: 'Not required',
	},
	scorpionexpress: {
		podNotAvailableMsg: 'Not available',
		labelNotAvailableMsg: 'Not required',
	},
	xpressbees: {
		podNotAvailableMsg: 'Not available',
		labelNotAvailableMsg: 'Not required',
	},
	common: {
		podNotAvailableMsg: 'Not generated',
		labelNotAvailableMsg: 'Not generated',
	},
};

const mode_to_vehicle_map = {
	air: ['priority_overnight', 'air'],
	surface: [
		'surface',
		'standard_overnight',
		'fedex_express_saver',
		'surface_express',
	],
};

const modes_map = {
	priority_overnight: 'Air (Priority)',
	standard_overnight: 'Air (Standard)',
	air: 'Air',
	surface: 'Surface',
	surface_express: 'Surface',
	fedex_express_saver: 'Surface',
};

const dp_costing_keys = {
	currency: [
		'green_tax',
		'transportation_charge',
		'discount',
		'invoice_value',
		'rate',
		'awb_charges',
		'handling_charges',
		'other_charges',
		'fuel_hike_surcharge',
		'address_correction_charges',
		'holding_charges',
		'total',
		'gst',
		'grand_total',
		'special_sunday_pickup_delivery_charges',
		'freight',
		'oda',
		'opa',
		'fsc',
		'fov',
	],
	date: ['ship_date'],
	weight: ['total_weight', 'single_shipment_weight', 'chargeable_weight'],
};

const entityType = {
	SHIPMENT: 1,
	PICKUP: 2,
};

const typeOfFirm = [
	{
		label: 'Public Limited Company',
		value: 0,
	},
	{
		label: 'Private Limited Company',
		value: 1,
	},
	{
		label: 'Joint-Venture Company',
		value: 2,
	},
	{
		label: 'Partnership Firm',
		value: 3,
	},
	{
		label: 'One Person Company',
		value: 4,
	},
	{
		label: 'Sole Proprietorship',
		value: 5,
	},
	{
		label: 'Government Organisation',
		value: 6,
	},
	{
		label: 'Non-Government Organisation (NGO)',
		value: 7,
	},
];

const auto_pickup_generation_for_manual_pickup_dp = [
	'safex',
	'spoton',
	'delex',
	'xpressbees',
	'scorpionexpress',
];
const auto_pickup_delivery_partners_mapping = [
	'delex',
	'spoton',
	'xpressbees',
	'gati',
	'lionex',
	'tciexpress',
	'oxyzenexpress',
	'babaexpress',
	'muditaexpress',
	'bluedart',
	'vxpress',
	'transmilo',
	'safex',
	'watsoo',
	'delhivery',
	'scorpionexpress',
	'movin',
	'ekart',
	'smartr',
	'safeship',
	'dependo',
];
const revenue_type = ['Interim Billed', 'Final Billed', 'Not Billed'];
const cost_type = ['Received', 'Not Received'];
const ndr_status = ['Pending', 'Initiated', 'Closed'];
const ndr_reason = [
	'RTO',
	'Re attempt',
	'Edit Consignee Details',
	'Book Appointment',
	'Upload Documents',
];
const termsLink =
	'https://garud-assets.s3.ap-south-1.amazonaws.com/common-files/Rocketbox+Online+Client+Master+Agreement.pdf';

const insurance_tnc_link =
	'https://garud-assets.s3.ap-south-1.amazonaws.com/common-files/secure_shipment_tnc.pdf';

const codLimit = 200000;

export {
	partner_to_mode_mapping,
	delivery_status,
	pickup_status,
	order_status,
	package_content_map,
	shipment_purpose_map,
	downloads_map,
	mode_to_vehicle_map,
	modes_map,
	dp_costing_keys,
	entityType,
	typeOfFirm,
	termsLink,
	auto_pickup_generation_for_manual_pickup_dp,
	transaction_type,
	with_insurance,
	is_direct_option,
	to_pay_status,
	remit_status,
	insurance_tnc_link,
	auto_pickup_delivery_partners_mapping,
	in_transit_status,
	with_rb_advantage,
	rto_status,
	codLimit,
	weight_discrepancie_status,
	zone_event,
	uploaded_file_status,
	revenue_type,
	cost_type,
	ndr_status,
	ndr_reason,
};
