import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCarrierSettingPopup } from 'actions/user';
import { CarriersRules } from 'modules/AutoAssignCarrier';
// import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

function AssignCarrierPopup(props) {
	const { carrier_setting_required } = props.user;
	const [showButton, setShowButton] = useState(false);
	const closeModel = () => {
		props.setCarrierSettingPopup({
			carrier_setting_required: false,
		});
		localStorage.removeItem('client_id');
		window.location.reload();
	};
	return (
		<Dialog
			fullScreen
			open={carrier_setting_required}
			disableBackdropClick
			TransitionComponent={Transition}
			keepMounted
		>
			{showButton && (
				<DialogActions>
					<Button color="primary" onClick={() => closeModel()}>
						<CloseIcon />
					</Button>
				</DialogActions>
			)}
			{carrier_setting_required && (
				<>
					<CarriersRules
						closeModel={() => {
							closeModel();
						}}
						showCloseButton={(val) => setShowButton(val)}
					/>
				</>
			)}
		</Dialog>
	);
}

const mapDispatchToProps = {
	setCarrierSettingPopup: (obj) => setCarrierSettingPopup(obj),
};

const mapStateToProps = (state) => ({
	appEngine: state.appEngine,
	user: state.user,
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AssignCarrierPopup)
);
