import React, { useEffect, useState } from 'react';
import 'assets/scss/material-dashboard-pro-react/loader.css';
// import initialLoader from 'assets/img/SR_Cargo_Animation_2.gif';
import SecondLoader from 'assets/img/SR_Cargo_loader.gif';
import cargoLoader from 'assets/img/cargoLoader.gif';
export default function Loader() {
	const [loop, setLoop] = useState(1);

	useEffect(() => {
		setTimeout(() => {
			setLoop(2);
		}, 5000);
	});

	return (
		<div className="loader-container">
			<img
				src={cargoLoader}
				alt="Loading..."
				className={`loader-gif`}
				style={{ opacity: loop === 1 ? '1' : '0', position: 'absolute' }}
			/>
			<img
				src={SecondLoader}
				alt="Loading..."
				className={`loader-gif`}
				style={{ opacity: loop !== 1 ? '1' : '0', position: 'absolute' }}
			/>
		</div>
	);
}
