import { SET_SHIPMENT_RATING_DATA } from 'actions/rating';

const ACTION_HANDLERS = {
	[SET_SHIPMENT_RATING_DATA]: (state, action) => ({
		...state,
		shipmentRatingData: action.payload,
	}),
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};

export default function ratingReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
