import {
	cardTitle,
	dangerColor,
	whiteColor,
	grayColor,
} from 'assets/jss/material-dashboard-pro-react.js';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js';

const validationFormsStyle = {
	...customCheckboxRadioSwitch,
	cardTitle: {
		...cardTitle,
		color: whiteColor,
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
	},
	formCategory: {
		marginBottom: '0',
		color: grayColor[0],
		fontSize: '14px',
		padding: '10px 0 10px',
	},
	formControl: {
		margin: '0 0 17px 0',
		paddingTop: '27px',
		position: 'relative',
		verticalAlign: 'unset',
		'& svg,& .fab,& .far,& .fal,& .fas,& .material-icons': {
			// color: grayColor[14],
			cursor: 'pointer',
		},
	},
	center: {
		textAlign: 'center',
	},
	justifyContentCenter: {
		justifyContent: 'center',
	},
	justifySpaceEven: {
		justifyContent: 'space-evenly',
	},
	justifySpaceEnd: {
		justifyContent: 'flex-end',
	},
	justifySpaceRight: {
		justifyContent: 'flex-start',
	},
	registerButton: {
		float: 'right',
	},
	danger: {
		color: dangerColor[0] + '!important',
	},
	justifyContentLeft: {
		justifyContent: 'left',
	},
};

export default validationFormsStyle;
