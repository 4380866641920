import { combineReducers } from 'redux';
import userReducer from './userReducer';
import warehouseReducer from './warehouseReducer';
import shipmentReducer from './shipmentReducer';
import pincodeServiceabilityReducer from './pincodeServiceabilityReducer';
import billingReducer from './billingReducer';
import pickupReducer from './pickupReducer';
import ratingReducer from './ratingReducer';
import dpAccountReducer from './dpAccountReducer';
import carrierSettingReducer from './carrierSetting';
import appEngineReducer from './appEngine';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';

export const makeRootReducer = (asyncReducers) => {
	return combineReducers({
		user: userReducer,
		warehouse: warehouseReducer,
		shipment: shipmentReducer,
		serviceability: pincodeServiceabilityReducer,
		billing: billingReducer,
		pickup: pickupReducer,
		rating: ratingReducer,
		dpAccount: dpAccountReducer,
		appEngine: appEngineReducer,
		carrierSetting: carrierSettingReducer,
		router: connectRouter(createBrowserHistory()),
		...asyncReducers,
	});
};

// export const injectReducer = (store, { key, reducer }) => {
//   if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

//   store.asyncReducers[key] = reducer
//   store.replaceReducer(makeRootReducer(store.asyncReducers))
// }

export default makeRootReducer;
