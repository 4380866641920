import { axiosInstance } from './apiInstance';
import { setUser, set403Error } from 'actions/user';
import get from 'lodash/get';
import { reloadUser } from 'utils/functions';
import fileDownload from 'js-file-download';
import { api } from 'config/variables';
import * as urls from './urls';
import { store } from '../index';

export const getZohoInvoices = (pageNo, client) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getZohoInvoices, {
				params: {
					page: pageNo || undefined,
					client_id: client?.id || undefined,
					page_size: 10,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const downloadPOD = (invoice_number) => {
	let reqObj = {
		invoice_number: invoice_number,
		emails: store.getState().billing.add_on_emails.toString(),
	};
	let body = JSON.stringify(reqObj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.downloadPOD, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getZohoPayments = (pageNo, client) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getZohoPayments, {
				params: {
					page: pageNo || undefined,
					client_id: client?.id || undefined,
					page_size: 10,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getZohoCreditNotes = (pageNo, client) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getZohoCreditNotes, {
				params: {
					page: pageNo || undefined,
					client_id: client?.id || undefined,
					page_size: 10,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getUnbilledShipments = (pageNo, filterVals, page_size) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getUnbilledShipments, {
				params: {
					page: pageNo || undefined,
					page_size: page_size || undefined,
					...filterVals,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getZohoDues = (filterVals) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getZohoDues, {
				params: {
					...filterVals,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const createOrderId = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.createPayment, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const createRazorPayOrder = (obj, token) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.razorpayOrderCreate, body, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getWalletHistory = (pageNo, page_size, filterVals, query) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getTransactions, {
				params: {
					page: pageNo || undefined,
					page_size: page_size || undefined,
					q: query || undefined,
					...filterVals,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const downloadPassbook = (queryParams) => {
	queryParams['emails'] = store.getState().billing.add_on_emails.toString();
	// let emails = store.getState().billing.add_on_emails.toString();
	return (dispatch) => {
		return axiosInstance
			.get(urls.downloadPassbook, {
				params: {
					...queryParams,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getInvoiceWorking = (invoice_id, invoice_num) => {
	let admin_api_url = api.server.split('/');
	admin_api_url.pop();
	admin_api_url = admin_api_url.join('/');
	return () => {
		return axiosInstance
			.get(
				`${admin_api_url}/api/zoho/download/invoice/?invoice_id=${invoice_id}`,
				{
					responseType: 'blob',
				}
			)
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					fileDownload(response.data, `${invoice_num}.xlsx`);
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getShiprocketWalletBalance = () => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getShiprocketBalance)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				let errMsg = get(err, 'response.data', false);
				if (
					errMsg?.detail !==
						'Please accept agreement terms to proceed further' &&
					(errResponse === 401 || errResponse === 403)
				) {
					dispatch(
						set403Error({
							error403: true,
							error403Data: errMsg,
						})
					);
					// alert(errMsg, { okButton: ' 戻る ' });
					// window.location.replace(`${api.sso_url}/new-kyc`);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const debitAmountFromSR = (obj) => {
	obj.payment_type = 'credit';
	return (dispatch) => {
		return axiosInstance
			.post(urls.credit_balance_from_sr_to_rb, obj)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'post',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getGrossMargin = (pageNo, filterVals = {}, page_size = 10) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getGrossMargin, {
				params: {
					page: pageNo || undefined,
					page_size: page_size || undefined,
					...filterVals,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const downloadCreditNotePDF = (creditnoteId) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getCreditNotePDFUrl, {
				params: {
					credit_note_id: creditnoteId,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data.url;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
