import _ from 'lodash';

export const verifyEmail = (value) => {
	var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (emailRex.test(value)) {
		return true;
	}
	return false;
};

export const verifyLength = (value, length) => {
	if (value.length >= length) {
		return true;
	}
	return false;
};

export const verifyExactLength = (value, length) => {
	if (value.length === length) {
		return true;
	}
	return false;
};

export const verifyMaxLength = (value, length) => {
	if (value.length <= length) {
		return true;
	}
	return false;
};

export const verifyMinLength = (value, length) => {
	if (value.length >= length) {
		return true;
	}
	return false;
};

export const verifyNumericLength = (value, length) => {
	const regex = /^[0-9]*$/;
	return regex.test(value) && value.length <= length;
};

export const verifyNumber = (value) => {
	var numberRex = new RegExp('^[0-9]+$');
	if (numberRex.test(value) && value > 0) {
		return true;
	}
	return false;
};

export const verifyInvoiceNumber = (value) => {
	var numberRex = new RegExp('^[0-9a-zA-Z-/]{1,25}$');
	if (numberRex.test(value) && value.length > 0) {
		return true;
	}
	return false;
};

export const verifyDecimalNumber = (value) => {
	var numberRex = /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]*\.[0-9]*[1-9][0-9]*)$/;
	if (numberRex.test(value)) {
		return parseFloat(value) >= 0.01;
	}
	return false;
};

export const parseErrors = (data, label = 'Item') => {
	let _camelCaseToVerbose = (text) => {
		return text.replace(/(?=[A-Z])/g, ' ');
	};

	let _underscoredToVerbose = (text) => {
		return text.replace(/[\d_]/g, ' ');
	};

	let _capitalize = (text) => {
		text = text.toLowerCase();
		text = text.charAt(0).toUpperCase() + text.slice(1);
		return text;
	};

	let _parseErrorItem = (item, listPos) => {
		var listItemTemplate = _.template(
				`<li class="list-item">
					<span class="list-item-pos">
						${label} <%= i %>
					</span>
				<%= content %></li>`
			),
			containerTemplate = _.template('<ul class="item"><%= content %></ul>'),
			output = [];

		_.each(item, function (value, key) {
			var fieldTemplate = _.template(
					`<li class="field">
									<span class="name">
										<%- name %>
									</span>
									<%= content %>
								</li>`
				),
				plainValueTemplate = _.template(
					'<span class="value"><%- value %></span>'
				),
				plainValue,
				listValue,
				content;

			if (_.isString(value)) {
				plainValue = value;
			} else if (_.isArray(value)) {
				if (_.isString(value[0])) {
					plainValue = value.join(' ');
				} else {
					listValue = _parseErrorList(value);
				}
			} else if (_.isPlainObject(value)) {
				plainValue = _parseErrorItem(value);
			}

			if (plainValue) {
				content = plainValueTemplate({ value: plainValue });
			} else if (listValue) {
				content = listValue;
			}

			if (content) {
				if (key === 'non_field_errors') {
					key = '';
				} else {
					if (key.search(/[A-Z]/) !== -1) key = _camelCaseToVerbose(key);

					if (key.search(/[\d_]/) !== -1) key = _underscoredToVerbose(key);

					key = _capitalize(key);
				}
				output.push(
					fieldTemplate({
						name: key ? `${key} :` : '',
						content: content,
					})
				);
			}
		});

		output = output.join('');

		if (output) {
			output = containerTemplate({ content: output });
			if (listPos) {
				output = listItemTemplate({
					i: listPos,
					content: output,
				});
			}
		}
		output = output.replace(/&quot;/g, '"');
		output = output.replace(/&gt;/g, '>');
		output = output.replace(/&lt;/g, '<');
		return output;
	};

	let _parseErrorList = (items) => {
		var containerTemplate = _.template('<ul class="list"><%= content %></ul>'),
			output = [];

		_.each(items, function (item, i) {
			if (!_.isEmpty(item)) {
				output.push(_parseErrorItem(item, i + 1));
			}
		});

		output = output.join('');

		if (output) {
			output = containerTemplate({ content: output });
		}

		return output;
	};

	if (_.isArray(data)) {
		return _parseErrorList(data);
	} else if (_.isPlainObject(data)) {
		return _parseErrorItem(data);
	} else {
		return _parseErrorItem(data);
	}
};

export const parseUploadErrors = (uploadDataErrors) => {
	let errors = [];
	for (const rowNum in uploadDataErrors) {
		for (const rowName in uploadDataErrors[rowNum]) {
			errors = [
				...errors,
				{
					row: parseInt(rowNum),
					field: rowName,
					err: uploadDataErrors[rowNum][rowName][0],
				},
			];
		}
	}
	errors = _.groupBy(errors, 'row');
	return errors;
};

// BACKUP
// const returnOnlyErrorArray = (errObj, objToNameMap, tempArr) => {
//   console.log('tempArr', tempArr)
//   Object.keys(errObj).forEach((key) => {
//     let isArr = Array.isArray(errObj[key])
//     if (isArr) {
//       tempArr.push(`${objToNameMap[key]} : ${errObj[key]}`)
//     } else {
//       returnOnlyErrorArray(errObj[key], objToNameMap[key], tempArr)
//     }
//   })
//   return tempArr
// }

// let obj = {
//   "username": ['invalid'],
//   "address": {
//     "add1" : ['invalid add1'],
//     "add2" : 'invalid add 2'
//   },
//   "packaging_unit_details": [{
//     "units": ["Ensure this value is greater than or equal to 1."],
//     "length": ["Ensure this value is greater than or equal to 1."],
//     "height": ["Ensure this value is greater than or equal to 1."]
//   }]
// }

// const returnOnlyErrorArray = (errObj, objToNameMap, tempArr) => {
//   console.log('tempArr', tempArr)
//   Object.keys(errObj).forEach((key, idx) => {
//     let isArr = Array.isArray(errObj[key])
//     if (isArr) {
//       debugger
//       if (typeof errObj[key][idx] === 'string') {
//         debugger
//         tempArr.push(`${objToNameMap[key]} : ${errObj[key]}`)
//       } else {
//         debugger
//         returnOnlyErrorArray(errObj[key][0], objToNameMap[key], tempArr)
//       }
//     } else {
//       debugger
//       returnOnlyErrorArray(errObj[key], objToNameMap[key], tempArr)
//     }
//   })
//   console.log('tempArr', tempArr)
//   return tempArr
// }

// const shipmentErrorHandling = (obj) => {
//   let objToNameMap = {
//     client_id: 'Client id',
//     mode: 'Mode',
//     delivery_partner_id: 'Delivery partner id',
//     from_warehouse_id: 'From warehouse id',
//     sender_contact_person_name: 'Sender contact person name',
//     sender_contact_person_contact_no: 'Sender contact person contact no',
//     sender_contact_person_email: 'Sender contact person email',
//     pickup_date: 'Pickup date',
//     pickup_time: 'Pickup time',
//     approx_weight: 'Approx weight',
//     packaging_unit_details: {
//       units: 'Units',
//       length: 'Length',
//       height: 'Height',
//       width: 'Width',
//     },
//     invoice_value: 'Invoice value',
//     eway_bill_no: 'Eway bill no',
//     remarks: 'Remarks',
//     to_warehouse_id: 'To warehouse id',
//     recipient_contact_person_name: 'Recipient contact person name',
//     recipient_contact_person_email: 'Recipient contact person email',
//     recipient_contact_person_contact_no: 'Recipient contact person contact no',
//     is_insured: 'Is insured',
//     no_of_units: 'No of units',
//     recipient_GST: 'Recipient GST',
//     invoice_number: 'Invoice number',
//   };
//   let data = returnOnlyErrorArray(obj, objToNameMap, [])
//   // console.log('data', data)
//   return errorElements(data)
// }

// shipmentErrorHandling(obj)

// export const throwErrors = (errObj, errFormtype) => {
//   let errorArray = []
//   if (typeof errObj === 'string') {
//     return 'Something went wrong'
//   } else {
//     let isNonFieldErr = Object.keys(errObj).includes('non_field_errors')
//     if (isNonFieldErr) {
//       Object.keys(errObj).forEach((err, idx) => {
//         errorArray = errObj[err].map((er) => {
//           return er
//         })
//       })
//     } else {
//       switch (errFormtype) {
//         case 'shipmentForm':
//           return shipmentErrorHandling(errObj)
//           break
//         case 'warehouseForm':
//           return warehouseErrorHandling(errObj)
//           break
//         case 'pocForm':
//           return pocErrorHandling(errObj)
//           break
//       }
//     }
//     return errorElements(errorArray)
//   }
// }

// const errorElements = (errArray) => {
//   let errorEle = errArray.length
//     ? <ul style={{'margin': 0, 'padding': '0 10px'}}>
//       { errArray.map(err => (<li>{err}</li>)) }
//     </ul>
//     : 'Something went wrong'
//   return errorEle
// }

// const pocErrorHandling = (obj) => {
//   let objToNameMap = {
//     user: {
//       first_name: 'First Name',
//       last_name: 'Last Name',
//       email: 'Email',
//       contact_no: 'Contact Number',
//     }
//   }
//   console.log(obj, objToNameMap)
//   let data = returnOnlyErrorArray(obj, objToNameMap, [])
//   // console.log('data', data)
//   return errorElements(data)
// }

// const shipmentErrorHandling = (obj) => {
//   let objToNameMap = {
//     client_id: 'Client id',
//     mode: 'Mode',
//     delivery_partner_id: 'Delivery partner id',
//     from_warehouse_id: 'From warehouse id',
//     sender_contact_person_name: 'Sender contact person name',
//     sender_contact_person_contact_no: 'Sender contact person contact no',
//     sender_contact_person_email: 'Sender contact person email',
//     pickup_date: 'Pickup date',
//     pickup_time: 'Pickup time',
//     approx_weight: 'Approx weight',
//     // packaging_unit_details: 'Packaging unit details',
//     packaging_unit_details: [{
//       units: 'Units',
//       length: 'Length',
//       height: 'Height',
//       width: 'Width',
//     }],
//     invoice_value: 'Invoice value',
//     eway_bill_no: 'Eway bill no',
//     remarks: 'Remarks',
//     to_warehouse_id: 'To warehouse id',
//     recipient_contact_person_name: 'Recipient contact person name',
//     recipient_contact_person_email: 'Recipient contact person email',
//     recipient_contact_person_contact_no: 'Recipient contact person contact no',
//     is_insured: 'Is insured',
//     no_of_units: 'No of units',
//     recipient_GST: 'Recipient GST',
//     invoice_number: 'Invoice number',
//   };
//   let data = returnOnlyErrorArray(obj, objToNameMap, [])
//   // console.log('data', data)
//   return errorElements(data)
// }

// const warehouseErrorHandling = (obj) => {
//   let objToNameMap = {
//     name: 'Name',
//     client_id: 'Client Id',
//     address: {
//         address_line_1: 'Address line 1',
//         address_line_2: 'Address line 2',
//         pincode: 'Pincode',
//         city: 'City',
//         state: 'State',
//         country: 'Country',
//     },
//     branch_code: 'Branch code',
//     contact_person_name: 'Contact person name',
//     contact_person_email: 'Contact person email',
//     contact_person_contact_no: 'Contact person contact no',
//   }
//   let data = returnOnlyErrorArray(obj, objToNameMap, [])
//   // console.log('data', data)
//   return errorElements(data)
// }
