// middleware/localStorageLoad.js

export const loadState = () => {
	try {
		const serializedState = localStorage.getItem('state');
		if (serializedState === null) {
			return undefined;
		}
		var result = JSON.parse(serializedState);
		delete result['router'];
		return result;
	} catch (err) {
		return undefined;
	}
};

export const saveState = (state) => {
	const serializedState = JSON.stringify(state);
	localStorage.setItem('state', serializedState);
};
