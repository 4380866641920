import { axiosInstance } from './apiInstance';
import axios from 'axios';
import { setUser } from 'actions/user';
import get from 'lodash/get';
import { reloadUser } from 'utils/functions';
import * as urls from './urls';

const successStatus = [200, 201, 204];
export const getDeliveryPartnerCftFactors = (clientId) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getDeliveryPartnerCFT, {
				params: {
					client_id: clientId,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const generateSignedUrl = (
	fileName,
	fileType,
	url = 'uploads/files/'
) => {
	let obj = {
		key: `${url}${fileName}`,
		file_type: fileType,
	};
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.commonFileUploadURL, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const uploadFiletoS3 = (file, s3Path, fields) => {
	var formData = new FormData();
	for (var key in fields) {
		formData.append(key, fields[key]);
	}
	formData.append('file', file);
	return (dispatch) => {
		return axios
			.post(s3Path, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (successStatus.includes(response.status)) {
					return true;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const updateUserTnCStatus = (clientId, obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.put(`/users/client/${clientId}/`, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const updateUser = (clientId, obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.patch(`/users/client/${clientId}/`, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const generateLogger = (msg, waybill_no) => {
	let obj = {
		msg: msg,
		waybill_no: waybill_no,
	};
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.addLogger, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
