import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import DialogContent from '@material-ui/core/DialogContent';
// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';
import extededFormStyles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js';
import Close from '@material-ui/icons/Close';

import { verifyLength, verifyMaxLength } from 'utils/validation';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RulesTabs from './RuleFormModalTabs';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';

import cheapest_icon from 'assets/img/cheapest_carrier_rule.svg';
import custom_icon from 'assets/img/custom.svg';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
	...styles,
	autoCompleteContainer: {
		margin: '16px 0',
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer',
	},
	autoCompleteContainerWarehouse: {
		margin: '14px 0',
	},
	alignCenter: {
		textAlign: 'center',
	},
	helperText: {
		margin: '4px 0',
		fontSize: '12px',
	},
	option: {
		fontFamily: 'inherit',
		fontSize: '13px',
		padding: '10px 20px',
		lineHeight: 2,
		'&:hover': {
			color: '#fff',
			background: '#6F57E9',
		},
	},
	autoCompleteMain: {
		'&:hover': {
			borderBottom: 'none!important',
			'&:before': {
				borderBottom: '1px solid #6F57E9!important',
			},
		},
		'&:after': {
			color: '#6F57E9!important',
			borderBottom: '1px solid #6F57E9!important',
		},
	},
	popperDisablePortal: {
		top: '100%',
	},
	selectCardBorder: {
		border: '1px solid grey',

		minHeight: '250px',
		padding: '50px',
		paddingBottom: '25px',
		boxShadow: '4px 6px 10px 0 rgba(209,209,209,1)',
		textAlign: 'center',
		cursor: 'pointer',
	},
	courierprioritycard: {
		border: '1px solid grey',
		minHeight: '80px',
		padding: '30px',
		// paddingBottom: '25px',
		boxShadow: '4px 6px 10px 0 rgba(209,209,209,1)',
		cursor: 'pointer',
		// textAlign: 'center',
	},
	pointer: {
		cursor: 'pointer',
	},
	selectcard: {
		border: '2px solid #6F57E9',
	},
});
const useExtendedFormStyles = makeStyles(extededFormStyles);

function EnhancedTableHead(props) {
	const { t } = useTranslation();
	const {
		order,
		orderBy,
		onRequestSort,
		// classes,
		// toggleTatModalHandler,
	} = props;
	const headCells = [
		{
			id: 'criteria',
			numeric: false,
			disablePadding: false,
			label: t('criteria'),
			hide: false,
		},
		{
			id: 'condition',
			numeric: false,
			disablePadding: false,
			label: t('condition'),
			hide: props.hide,
		},
		{
			id: 'actions',
			numeric: false,
			disablePadding: false,
			label: t('action'),
			hide: props.hide,
		},
	];
	const filteredHeadCells = headCells.filter((cell) => !cell.hide);

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{/* <TableCell padding="checkbox" /> */}
				{filteredHeadCells?.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
export default function AddRuleForm(props) {
	const { enqueueSnackbar } = useSnackbar();

	const [rulesDialog, setrulesDialog] = React.useState(false);
	const [ruleNameState, setRuleNameState] = React.useState('');
	const [ruleName, setRuleName] = React.useState('');
	const [prioritycouriercard, setPriorityCourierCard] = React.useState(0);

	const [modes, setmodes] = React.useState([]);
	const [orderTypes, setOrderTypes] = React.useState([]);
	const [isAppointment, setIsAppointment] = React.useState('');
	const [sourcePincodes, setSourcePincodes] = React.useState('');
	const [from_zone, setFromZone] = React.useState('');
	const [to_zone, setToZone] = React.useState('');
	const [destinationPincodes, setDestinationPincodes] = React.useState('');
	const [criteriaSettings, setCriteriaSettings] = React.useState([]);
	const [delivery_partner_list, setDeliveryPartnerList] = React.useState([]);
	const [weightRange, setWeightRange] = React.useState({
		min: '',
		max: '',
		min_state: '',
		max_state: '',
	});
	const [invoiceValue, setInvoiceValue] = React.useState({
		min: '',
		max: '',
		min_state: '',
		max_state: '',
	});
	const [
		destination_pincode_file_name,
		setDestPincodeFileName,
	] = React.useState('');
	const [source_pincode_file_name, setSourcePincodeFileName] = React.useState(
		''
	);

	const [
		destination_pincode_file_path,
		setDestPincodeFilePath,
	] = React.useState('');
	const [source_pincode_file_path, setSourcePincodeFilePath] = React.useState(
		''
	);
	const [dps_priority, setDpsPriority] = React.useState({
		one: '',
		two: '',
		three: '',
		four: '',
		five: '',
	});
	const [criteria_del_data, setCriteriaDelData] = React.useState('');
	const [rule_id, setRuleId] = React.useState(null);

	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event, type) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setCriteriaDelData(type);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'transitions-popper' : undefined;

	React.useEffect(() => {
		props.setBackdrop(false);
		props.getDeliveryPartners().then((res) => {
			setDeliveryPartnerList(res);
		});
	}, []);
	let i = 0;
	React.useEffect(() => {
		if (
			props.carrierSetting.rule_edit_data !== {} &&
			i === 0 &&
			props.history?.location?.pathname?.includes('/edit-rule/')
		) {
			i++;
			const { rule_edit_data } = props.carrierSetting;
			let criteria = rule_edit_data.criteria;
			const { rule_name, rule_type } = rule_edit_data;
			setRuleName(() => {
				return rule_name;
			});
			setRuleId(rule_edit_data.id);
			setPriorityCourierCard(rule_type == 'cheapest_first' ? 1 : 2);
			if (rule_type == 'custom') {
				let _obj = rule_edit_data.carriers_list.reduce(function (
					result,
					item,
					i
				) {
					let key_name = ['one', 'two', 'three', 'four', 'five'];
					result[key_name[i]] = item.id;
					return result;
				},
				{});
				setDpsPriority(_obj);
			}
			Object.keys(criteria).map(async (key) => {
				if (Array.isArray(criteria[key])) {
					if (
						key == 'source_pincode' ||
						key == 'destination_pincode' ||
						key == 'from_zone' ||
						key == 'to_zone'
					) {
						updateSetting(key, criteria[key].join(','));
					} else {
						criteria[key].map((val) => {
							updateSetting(key, val);
						});
					}
				} else if (key === 'is_appointment_taken') {
					updateSetting(key, criteria[key]);
				} else {
					Object.keys(criteria[key]).map((_key) => {
						updateSetting(key, criteria[key][_key], _key);
					});
				}
			});
		}
	}, [props.carrierSetting.rule_edit_data]);

	const openDialog = () => {
		setrulesDialog(true);
	};

	const handleDpSelection = (e, priority) => {
		setDpsPriority((prevState) => {
			return { ...prevState, [priority]: e.target.value };
		});
	};

	const handleFileUpload = (type, path) => {
		switch (type) {
			case 'source_pincode':
				setSourcePincodeFilePath(path);
				break;
			case 'destination_pincode':
				setDestPincodeFilePath(path);
				break;

			default:
				break;
		}
		// setLogoUrl(path);
		// setLogoUrlState('success');
	};
	const updateSetting = (type, value, key) => {
		switch (type) {
			case 'mode':
				if (value == '') {
					setmodes([]);
					_setting = criteriaSettings.filter((val) => val.type !== type);
					setCriteriaSettings(_setting);
					return;
				}
				if (!modes.includes(value)) {
					setmodes([...modes, value]);
					if (criteriaSettings.filter((val) => val.type == type).length == 0) {
						setCriteriaSettings((prevState) => {
							return [
								...prevState,
								{
									type: type,
									value: [...modes, value],
								},
							];
						});
					} else {
						_setting = criteriaSettings.map((val) => {
							if (val.type == type) {
								return {
									type: type,
									value: [...modes, value],
								};
							}
							return val;
						});
						// setCriteriaSettings(_setting);
						setCriteriaSettings(_setting);
					}
				} else {
					setmodes(modes.filter((val) => val != value));
					if (modes.filter((val) => val != value).length !== 0) {
						_setting = criteriaSettings.map((val) => {
							if (val.type == type) {
								return {
									type: type,
									value: modes.filter((val) => val != value),
								};
							}
							return val;
						});
					} else {
						_setting = criteriaSettings.filter((val) => val.type !== type);
					}
					setCriteriaSettings(_setting);
				}
				break;
			case 'order_type':
				if (value == '') {
					setOrderTypes([]);
					_setting = criteriaSettings.filter((val) => val.type !== type);
					setCriteriaSettings(_setting);
					return;
				}
				if (!orderTypes.includes(value)) {
					setOrderTypes([...orderTypes, value]);

					if (criteriaSettings.filter((val) => val.type == type).length == 0) {
						setCriteriaSettings((prevState) => {
							return [
								...prevState,
								{
									type: type,
									value: value == '' ? [] : [...orderTypes, value],
								},
							];
						});
					} else {
						var _setting = criteriaSettings.map((val) => {
							if (val.type == type) {
								return {
									type: type,
									value: [...orderTypes, value],
								};
							}
							return val;
						});
						setCriteriaSettings(_setting);
					}
				} else {
					setOrderTypes(orderTypes.filter((val) => val != value));
					if (orderTypes.filter((val) => val != value).length !== 0) {
						_setting = criteriaSettings.map((val) => {
							if (val.type == type) {
								return {
									type: type,
									value: orderTypes.filter((val) => val != value),
								};
							}
							return val;
						});
					} else {
						_setting = criteriaSettings.filter((val) => val.type !== type);
					}
					setCriteriaSettings(_setting);
				}
				break;
			case 'source_pincode':
				setSourcePincodes(value);
				updateCriteria(type, value);
				break;
			case 'is_appointment_taken':
				setIsAppointment(value);
				updateCriteria(type, value);
				break;

			case 'destination_pincode':
				setDestinationPincodes(value);
				updateCriteria(type, value);
				break;
			case 'weight':
				setWeightRange((prevState) => {
					updateCriteria(type, value, { ...prevState, [key]: value });
					return { ...prevState, [key]: value };
				});
				break;
			case 'invoice':
				setInvoiceValue((prevState) => {
					updateCriteria(type, value, { ...prevState, [key]: value });
					return { ...prevState, [key]: value };
				});
				break;
			case 'from_zone':
				setFromZone(value);
				updateCriteria(type, value);
				break;
			case 'to_zone':
				setToZone(value);
				updateCriteria(type, value);
				break;

			default:
				break;
		}
	};

	const updateCriteria = (type, value, key) => {
		let _setting;
		setCriteriaSettings((prevState) => {
			if (
				prevState.filter((val) => val.type == type).length == 0 &&
				value !== ''
			) {
				// setCriteriaSettings((prevState) => {
				return [
					...prevState,
					{
						type: type,
						value: key ? key : [value],
					},
				];
				// });
			} else if (value == '' && !key) {
				_setting = prevState.filter((val) => val.type != type);
				return _setting;
				// setCriteriaSettings(_setting);
			} else if (key && value == '') {
				let new_obj = { min: key.min, max: key.max };
				let _empty = Object.values(new_obj).every((value) => value === '');
				_empty && updateCriteria(type, value);
				return prevState;
			} else {
				_setting = prevState.map((val) => {
					if (val.type == type) {
						return {
							type: type,
							value: key ? key : [value],
						};
					}
					return val;
				});
				return _setting;
				// setCriteriaSettings(_setting);
			}
		});
	};

	const validateForm = () => {
		if (ruleNameState === 'error' || ruleName == '') {
			setRuleNameState('error');
			return;
		} else if (criteriaSettings.length === 0) {
			enqueueSnackbar('Please select criteria', {
				variant: 'error',
				autoHideDuration: 3000,
			});
			return;
		} else {
			saveRule();
		}
	};

	const saveRule = () => {
		// Object.keys()
		let appointment_val = isAppointment;
		if (appointment_val) {
			if (appointment_val !== true) {
				appointment_val = isAppointment == 'yes' ? true : false;
			}
		} else {
			appointment_val = false;
		}
		props.setBackdrop(true);
		const body = {
			carrier_setting_id: props.carrierSetting.carrier_setting_id,
			rule_type:
				prioritycouriercard == 1
					? 'cheapest_first'
					: prioritycouriercard == 2
					? 'custom'
					: '',
			min_invoice_value: invoiceValue.min,
			max_invoice_value: invoiceValue.max,
			min_weight: weightRange.min,
			max_weight: weightRange.max,
			pickup_pincodes: sourcePincodes.split(','),
			destination_pincodes: destinationPincodes.split(','),
			shipment_type: orderTypes,
			is_appointment_taken: appointment_val,
			mode: modes,
			priority: props.carrierSetting.rule_priority,
			carriers_list: renameKeys(dps_priority, 'id'),
			destination_pincodes_url: destination_pincode_file_path,
			pickup_pincodes_url: source_pincode_file_path,
			rule_name: ruleName,
			from_zone: from_zone.split(',').length !== 0 ? from_zone.split(',') : [],
			to_zone: to_zone.split(',').length !== 0 ? to_zone.split(',') : [],
		};
		if (rule_id) {
			body.id = rule_id;
		}
		if (!rule_id) {
			props
				.saveRule(body)
				.then(() => {
					props.setBackdrop(false);
					enqueueSnackbar('Rule Added Succesfully', {
						variant: 'success',
						autoHideDuration: 500,
					});
					props.history.goBack();
				})
				.catch((err) => {
					props.setBackdrop(false);
					let error = err[0]?.errors[0]?.message;
					enqueueSnackbar(error, {
						variant: 'error',
						autoHideDuration: 3000,
					});
				});
		} else {
			props
				.editRule(body)
				.then(() => {
					props.setBackdrop(false);
					enqueueSnackbar('Rule updated Succesfully', {
						variant: 'success',
						autoHideDuration: 500,
					});
					props.history.goBack();
				})
				.catch((err) => {
					props.setBackdrop(false);
					let error = err[0]?.errors[0]?.message;

					enqueueSnackbar(error, {
						variant: 'error',
						autoHideDuration: 3000,
					});
				});
		}
	};

	function renameKeys(obj, newKeys) {
		renameKeystoIndex(obj);
		const keyValues = Object.keys(obj).map((key) => {
			const newKey = newKeys;
			return { [newKey]: obj[key], name: get_dp_name_by_id(obj[key]) };
		});
		return keyValues;
	}
	function get_dp_name_by_id(dp_id) {
		let _dp_name = delivery_partner_list.filter((dp) => dp.id == dp_id)[0]
			?.name;
		return _dp_name;
	}

	function renameKeystoIndex(obj) {
		let key_name = ['one', 'two', 'three', 'four', 'five'];
		const keyValues = Object.keys(obj).map((key, i) => {
			const newKey = key_name[i];
			return { [newKey]: obj[key] };
		});
		return keyValues;
	}
	const delData = (comand) => {
		setAnchorEl(null);

		if (comand === 'yes') {
			let d_ = criteriaSettings.filter((val) => val.type === criteria_del_data);
			if (d_.length !== 0) {
				updateSetting(d_[0].type, '');
			}
		}
	};

	const checkdpId = (id) => {
		let obj_val = Object.values(dps_priority);
		return obj_val.includes(id);
	};

	const classes = useStyles();
	const extendedClasses = useExtendedFormStyles();

	return (
		<>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="primary" text>
							<CardText color="primary">
								<h4 className={classes.cardTitle}>{t('rb_select')}</h4>
							</CardText>
						</CardHeader>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={6}>
									<CustomInput
										success={ruleNameState === 'success'}
										error={ruleNameState === 'error'}
										labelText={t('rule_name') + ' *'}
										helperText={t('max_50_chars')}
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											onChange: (event) => {
												if (
													verifyMaxLength(event.target.value, 50) &&
													verifyLength(event.target.value, 1)
												) {
													setRuleNameState('success');
												} else {
													setRuleNameState('error');
												}
												setRuleName(event.target.value);
											},
											// disabled:
											// 	submitStatus || props?.warehouse?.warehouseEditStatus,
											type: 'text',
											autoComplete: 'disabled',
											value: ruleName,
										}}
									/>
								</GridItem>
							</GridContainer>
							<Popper id={id} open={open} anchorEl={anchorEl} transition>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps} timeout={350}>
										<Card>
											<CardBody>Are you sure you want to delete?</CardBody>
											<CardFooter className={classes.justifySpaceEven}>
												<Button
													color="primary"
													// disabled={submitStatus}
													onClick={() => delData('cancel')}
												>
													{t('cancel')}
												</Button>
												<Button
													color="primary"
													// disabled={submitStatus}
													// onClick={submitHandler}
													onClick={() => delData('yes')}
												>
													{t('yes')}
												</Button>
											</CardFooter>
										</Card>
									</Fade>
								)}
							</Popper>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									{/* <InputLabel
										className={classes.label}
									>
										{t('rule')}
									</InputLabel> */}
									<label>
										<b>{t('rule')}</b>
									</label>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<Card>
										<TableContainer className={classes.dpRateContainer}>
											<Table
												stickyHeader
												className={classes.table}
												aria-labelledby="tableTitle"
												size="small"
												aria-label="enhanced table"
											>
												<EnhancedTableHead
													classes={classes}
													onRequestSort={() => {
														console.log('call');
													}}
													toggleTatModalHandler={() => {
														console.log('call');
													}}
													// rowCount={criteriaSettings.length}
													hide={false}
												/>
												<TableBody>
													{criteriaSettings.map((val, i) => {
														return (
															<TableRow key={i}>
																<TableCell padding="div">
																	{t(val.type)}
																</TableCell>
																<TableCell component="div">
																	{Array.isArray(val.value)
																		? val.value.join(',')
																		: `${val?.value?.min}-${val?.value?.max}`}
																</TableCell>
																<TableCell component="div">
																	<DeleteIcon
																		aria-describedby={id}
																		onClick={(e) => handleClick(e, val.type)}
																		className={classes.pointer}
																	/>
																	<EditIcon />
																</TableCell>
															</TableRow>
														);
													})}
												</TableBody>
											</Table>
										</TableContainer>

										<div
											style={{ textAlign: 'center', cursor: 'pointer' }}
											onClick={() => openDialog()}
										>
											<span>{t('click_to_add_criteria')}</span>
										</div>
									</Card>
								</GridItem>
							</GridContainer>

							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<label>
										<b>Courier Priority</b>
									</label>
								</GridItem>
								<GridItem
									xs={3}
									sm={3}
									onClick={() => setPriorityCourierCard(1)}
									className={classes.alignCenter}
								>
									<Card
										className={`${classes.courierprioritycard} ${
											prioritycouriercard === 1 && classes.selectcard
										}`}
									>
										<div className={classes.alignCenter}>
											<img src={cheapest_icon} width="50" />
										</div>
										<h5>{t('cheapest')}</h5>
										<span>
											{t('carrier_will_be_assigned_based_on_cheapest_cost')}
										</span>
									</Card>
								</GridItem>
								<GridItem
									xs={3}
									sm={3}
									onClick={() => setPriorityCourierCard(2)}
									className={classes.alignCenter}
								>
									<Card
										className={`${classes.courierprioritycard} ${
											prioritycouriercard === 2 && classes.selectcard
										}`}
									>
										<div className={classes.alignCenter}>
											<img src={custom_icon} width="50" />
										</div>
										<h5>{t('custom')}</h5>
										<span>
											{t('carrier_will_be_assigned_based_on_you_set_priority')}
										</span>
									</Card>
								</GridItem>
							</GridContainer>

							{prioritycouriercard == 2 && (
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										<label>
											<b>Set Courier Priority</b>
										</label>
									</GridItem>
									<GridItem xs={3} sm={3} md={3}>
										<FormControl
											fullWidth
											className={extendedClasses.selectFormControl}
										>
											<InputLabel
												htmlFor="simple-select-shipmentPurpose"
												className={extendedClasses.selectLabel}
											>
												{t('priority', { sequence: '1st' })}
											</InputLabel>
											<Select
												MenuProps={{ className: extendedClasses.selectMenu }}
												classes={{ select: extendedClasses.select }}
												value={dps_priority.one}
												onChange={(e) => handleDpSelection(e, 'one')}
												inputProps={{ name: 'shipmentPurpose' }}
											>
												{delivery_partner_list.map((dps, i) => {
													return (
														<MenuItem
															disabled={checkdpId(dps.id)}
															classes={{
																root: extendedClasses.packageMenuItem,
																selected:
																	extendedClasses.selectMenuItemSelected,
															}}
															key={i}
															value={dps.id}
														>
															{dps.name}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={3} sm={3} md={3}>
										<FormControl
											fullWidth
											className={extendedClasses.selectFormControl}
										>
											<InputLabel
												htmlFor="simple-select-shipmentPurpose"
												className={extendedClasses.selectLabel}
											>
												{t('priority', { sequence: '2nd' })}
											</InputLabel>
											<Select
												MenuProps={{ className: extendedClasses.selectMenu }}
												classes={{ select: extendedClasses.select }}
												value={dps_priority.two}
												onChange={(e) => handleDpSelection(e, 'two')}
												inputProps={{ name: 'shipmentPurpose' }}
											>
												{delivery_partner_list.map((dps, i) => {
													return (
														<MenuItem
															disabled={checkdpId(dps.id)}
															// disabled={shipmentMode === 'PRIORITY_OVERNIGHT'}
															classes={{
																root: extendedClasses.packageMenuItem,
																selected:
																	extendedClasses.selectMenuItemSelected,
															}}
															key={i}
															value={dps.id}
														>
															{dps.name}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={3} sm={3} md={3}>
										<FormControl
											fullWidth
											className={extendedClasses.selectFormControl}
										>
											<InputLabel
												htmlFor="simple-select-shipmentPurpose"
												className={extendedClasses.selectLabel}
											>
												{t('priority', { sequence: '3rd' })}
											</InputLabel>
											<Select
												MenuProps={{ className: extendedClasses.selectMenu }}
												classes={{ select: extendedClasses.select }}
												value={dps_priority['three']}
												onChange={(e) => handleDpSelection(e, 'three')}
												inputProps={{ name: 'shipmentPurpose' }}
											>
												{delivery_partner_list.map((dps, i) => {
													return (
														<MenuItem
															disabled={checkdpId(dps.id)}
															// disabled={shipmentMode === 'PRIORITY_OVERNIGHT'}
															classes={{
																root: extendedClasses.packageMenuItem,
																selected:
																	extendedClasses.selectMenuItemSelected,
															}}
															key={i}
															value={dps.id}
														>
															{dps.name}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={3} sm={3} md={3}>
										<FormControl
											fullWidth
											className={extendedClasses.selectFormControl}
										>
											<InputLabel
												htmlFor="simple-select-shipmentPurpose"
												className={extendedClasses.selectLabel}
											>
												{t('priority', { sequence: '4th' })}
											</InputLabel>
											<Select
												MenuProps={{ className: extendedClasses.selectMenu }}
												classes={{ select: extendedClasses.select }}
												value={dps_priority.four}
												onChange={(e) => handleDpSelection(e, 'four')}
												inputProps={{ name: 'shipmentPurpose' }}
											>
												{delivery_partner_list.map((dps, i) => {
													return (
														<MenuItem
															disabled={checkdpId(dps.id)}
															// disabled={shipmentMode === 'PRIORITY_OVERNIGHT'}
															classes={{
																root: extendedClasses.packageMenuItem,
																selected:
																	extendedClasses.selectMenuItemSelected,
															}}
															key={i}
															value={dps.id}
														>
															{dps.name}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={3} sm={3} md={3}>
										<FormControl
											fullWidth
											className={extendedClasses.selectFormControl}
										>
											<InputLabel
												htmlFor="simple-select-shipmentPurpose"
												className={extendedClasses.selectLabel}
											>
												{t('priority', { sequence: '5th' })}
											</InputLabel>
											<Select
												MenuProps={{ className: extendedClasses.selectMenu }}
												classes={{ select: extendedClasses.select }}
												value={dps_priority.five}
												onChange={(e) => handleDpSelection(e, 'five')}
												inputProps={{ name: 'shipmentPurpose' }}
											>
												{delivery_partner_list.map((dps, i) => {
													return (
														<MenuItem
															disabled={checkdpId(dps.id)}
															// disabled={shipmentMode === 'PRIORITY_OVERNIGHT'}
															classes={{
																root: extendedClasses.packageMenuItem,
																selected:
																	extendedClasses.selectMenuItemSelected,
															}}
															key={i}
															value={dps.id}
														>
															{dps.name}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									</GridItem>
								</GridContainer>
							)}
						</CardBody>
						<CardFooter className={classes.justifySpaceEven}>
							<Button
								color="primary"
								// disabled={submitStatus}
								onClick={() => validateForm()}
							>
								{t('submit')}
							</Button>
							<Button
								color="neutral"
								variant="outlined"
								// disabled={submitStatus}
								onClick={() => props.history.goBack()}
							>
								{t('cancel')}
							</Button>
						</CardFooter>
					</Card>
				</GridItem>
			</GridContainer>
			<Dialog
				fullWidth
				maxWidth="md"
				open={rulesDialog}
				onClose={() => {
					setrulesDialog(false);
				}}
				scroll="paper"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					<div className={classes.titleContainer}>
						<span style={{ lineHeight: '20px' }}>
							{' '}
							Select criteria and add condition <br></br>
							<small className={classes.helperText}>
								You can also select miltipal criteria for single rule
							</small>
						</span>
						<Close
							onClick={() => {
								setrulesDialog(false);
							}}
						/>
					</div>
				</DialogTitle>
				<DialogContent dividers>
					<RulesTabs
						setSetting={(type, value, key) => {
							updateSetting(type, value, key);
						}}
						modes={modes}
						orderTypes={orderTypes}
						is_appointment_taken={isAppointment}
						sourcePincodes={sourcePincodes}
						fromZone={from_zone}
						toZone={to_zone}
						destinationPincodes={destinationPincodes}
						handleFileUpload={handleFileUpload}
						setDestPincodeFileName={setDestPincodeFileName}
						destination_pincode_file_name={destination_pincode_file_name}
						source_pincode_file_name={source_pincode_file_name}
						setSourcePincodeFileName={setSourcePincodeFileName}
						weightRange={weightRange}
						invoiceValue={invoiceValue}
						{...props}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
}
