import { axiosInstance } from './apiInstance';
import { setUser } from 'actions/user';
import { reloadUser } from 'utils/functions';
import * as urls from './urls';

export const getShopifyAccountList = (pageNo) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.createShopifyChannel, {
				params: {
					page: pageNo || undefined,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const addShopify = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.createShopifyChannel, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const deleteShopify = (id) => {
	return (dispatch) => {
		return axiosInstance
			.delete(`/shopify/create/${id}`)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'delete',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 204) {
					return true;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
