export const SET_CLIENT_DETAILS = 'SET_CLIENT_DETAILS';
export const SET_USER = 'SET_USER';
export const SET_CLIENT_PERMISSIONS = 'SET_CLIENT_PERMISSIONS';
export const SET_CLIENT_TERM = 'SET_CLIENT_TERM';
export const SET_403_ERROR = 'SET_403_ERROR';
export const SET_CONTACT_US_POPUP = 'SET_CONTACT_US_POPUP';
export const SET_DASHBOARD_STATS_MONTH = 'SET_DASHBOARD_STATS_MONTH';
export const SET_CARRIER_SETTING_POPUP = 'SET_CARRIER_SETTING_POPUP';
export const SET_SECURE_SHIPMENT_DETAILS_LIST =
	'SET_SECURE_SHIPMENT_DETAILS_LIST';
export const SET_ZONE_UPLOADER_LIST = 'SET_ZONE_UPLOADER_LIST';

export const setUser = (payload) => {
	return {
		type: SET_USER,
		payload,
	};
};

export const setClientDetails = (payload) => {
	return {
		type: SET_CLIENT_DETAILS,
		payload,
	};
};

export const setPermissions = (payload) => {
	return {
		type: SET_CLIENT_PERMISSIONS,
		payload,
	};
};
export const setTerm = (payload) => {
	return {
		type: SET_CLIENT_TERM,
		payload,
	};
};
export const set403Error = (payload) => {
	return {
		type: SET_403_ERROR,
		payload,
	};
};
export const setContactusPopup = (payload) => {
	return {
		type: SET_CONTACT_US_POPUP,
		payload,
	};
};

export const setDashboardDate = (payload) => {
	return {
		type: SET_DASHBOARD_STATS_MONTH,
		payload,
	};
};

export const setCarrierSettingPopup = (payload) => {
	return {
		type: SET_CARRIER_SETTING_POPUP,
		payload,
	};
};

export const setSecureShipmentDetailsList = (payload) => {
	return {
		type: SET_SECURE_SHIPMENT_DETAILS_LIST,
		payload,
	};
};

export const setZoneUploaderList = (payload) => {
	return {
		type: SET_ZONE_UPLOADER_LIST,
		payload,
	};
};
