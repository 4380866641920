import { SET_DP_ACCOUNT_EDIT_STATUS } from 'actions/dp_account';

const ACTION_HANDLERS = {
	[SET_DP_ACCOUNT_EDIT_STATUS]: (state, action) => {
		return {
			...state,
			dpAccountEditData: action.payload.data,
		};
	},
};

const initialState = {};

export default function dpAccountReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
