export const SET_PICKUP_LIST = 'SET_PICKUP_LIST';
export const SET_PICKUP_EDIT_STATUS = 'SET_PICKUP_EDIT_STATUS';

export const setPickupList = (payload) => {
	return {
		type: SET_PICKUP_LIST,
		payload,
	};
};

export const setPickupEditStatus = (payload) => {
	return {
		type: SET_PICKUP_EDIT_STATUS,
		payload,
	};
};
