import { connect } from 'react-redux';
import { getClientList } from 'apilist';
/*  This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component - in this case, the counter:   */

import ClientSearchBar from './Component/ClientSearchBar';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. Here we are
    implementing our wrapper around increment; the component doesn't care   */

const mapDispatchToProps = {
	getClientList: (status = 'active', value, is_on_advanced_plan) =>
		getClientList(status, value, is_on_advanced_plan),
};

const mapStateToProps = (state) => ({
	user: state.user,
});

const containerCreator = connect(mapStateToProps, mapDispatchToProps);

export const ClientSearch = containerCreator(ClientSearchBar);
