import { connect } from 'react-redux';
import {
	optSecureShipment,
	getUserCarriers,
	deleteDisableCarrier,
	createDisableCarrier,
	getAutoAssignCarrier,
	updateAutoAssignCarrier,
	createAutoAssignCarrier,
	deleteAutoAssignCarrier,
	getAllModeLists,
} from 'apilist';
/*  This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component - in this case, the counter:   */
import { setBackdrop } from 'actions/appEngine';
import { setUser } from 'actions/user';

import DisableCarriers from '../components/DisableCarriers';
import SetRulesCarriers from '../components/SetRules';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. Here we are
    implementing our wrapper around increment; the component doesn't care   */

const mapDispatchToProps = {
	optSecureShipment,
	setBackdrop,
	setUser,
	getUserCarriers,
	createDisableCarrier,
	deleteDisableCarrier,
	getAutoAssignCarrier,
	updateAutoAssignCarrier,
	createAutoAssignCarrier,
	deleteAutoAssignCarrier,
	getAllModeLists,
};

const mapStateToProps = (state) => ({
	user: state.user,
	appEngine: state.appEngine,
	pickup: state.pickup,
});

const containerCreator = connect(mapStateToProps, mapDispatchToProps);

export const Carriers = containerCreator(DisableCarriers);
export const CarriersRules = containerCreator(SetRulesCarriers);
