import React, { useEffect } from 'react';
import {
	GridContextProvider,
	GridDropZone,
	GridItem,
	swap,
} from 'react-grid-dnd';
import '../../../assets/scss/drag-drop-grid.scss';

export default function DragDrop(props) {
	const [items, setItems] = React.useState({
		left: props.data
			? props?.data?.filter(
					(item) => item?.delivery_partner?.common_name !== 'rb_advantage'
			  )
			: [],
	});
	const [height, setHeight] = React.useState('50px');

	useEffect(() => {
		setHeight((Math.ceil(items.left.length / 3) * 150 + 70).toString() + 'px');
	});
	const onChange = (sourceId, sourceIndex, targetIndex) => {
		const result = swap(items['left'], sourceIndex, targetIndex);
		props.setitemData(result);
		props.setEdit(true);
		return setItems({
			...items,
			['left']: result,
		});
	};
	// const onSave = () => {
	// 	props.updateAutoAssignRule(items.left);
	// };

	return (
		<GridContextProvider
			onChange={(sourceId, sourceIndex, targetIndex, targetId) =>
				onChange(sourceId, sourceIndex, targetIndex, targetId)
			}
		>
			<div className="container">
				<GridDropZone
					className="dropzone left"
					style={{ height: height }}
					boxesPerRow={3}
					rowHeight={150}
				>
					{items.left.map(
						(item) =>
							item?.delivery_partner?.common_name !== 'rb_advantage' && (
								<GridItem
									key={item.id}
									style={{ cursor: 'pointer', margin: '10px', padding: '5px' }}
								>
									<div className="grid-item-content">
										<img src={item.delivery_partner.logo} width="70" />

										<h5>{item.delivery_partner.name}</h5>
										<span>{item.common_name}</span>
									</div>
								</GridItem>
							)
					)}
				</GridDropZone>
			</div>
		</GridContextProvider>
	);
}
