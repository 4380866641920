import { SET_PICKUP_EDIT_STATUS } from 'actions/pickup';

const ACTION_HANDLERS = {
	[SET_PICKUP_EDIT_STATUS]: (state, action) => {
		return {
			...state,
			pickupEditStatus: action.payload.status,
			pickupEditData: action.payload.data,
			pickupTether: action.payload.tetherStatus,
		};
	},
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};

export default function pickupReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
