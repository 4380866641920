import { SET_ADDON_EMAIL } from 'actions/billing';

const ACTION_HANDLERS = {
	[SET_ADDON_EMAIL]: (state, action) => {
		return {
			...state,
			add_on_emails: action.payload,
		};
	},
};

const initialState = { add_on_emails: [] };

export default function billingReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
