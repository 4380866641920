import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const ClientSearchBar = (props) => {
	const [clientList, setClientList] = useState([]);
	const [search, setSearch] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
		SearchClients();
	}, [search]);
	const SearchClients = () => {
		let is_on_advanced_plan = props.isStaff ? props.isStaff : false;
		if (search !== '') {
			props
				.getClientList('active', search, is_on_advanced_plan)
				.then((res) => {
					setClientList(search !== '' ? res : []);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			setClientList([]);
		}
	};
	return (
		<Tooltip
			placement="bottom-start"
			title={!clientList.length ? t('type_to_search_clients') : ''}
		>
			<Autocomplete
				options={search !== '' ? clientList : []}
				disablePortal={true}
				blurOnSelect={true}
				value={props.value}
				noOptionsText={t('enter_char_to_search')}
				disableClearable={!props.clearable == true}
				getOptionLabel={(option) => {
					const name =
						props.showClient_id && option.client_name
							? option.client_name +
							  ' #(' +
							  option.id +
							  ')' +
							  ' (' +
							  option.rb_client_type +
							  ')'
							: option.client_name && option.rb_client_type
							? option.client_name + ' (' + option.rb_client_type + ')'
							: '';
					return name;
				}}
				onClose={(e) => {
					e.preventDefault();
				}}
				onChange={props.onChange}
				classes={props.classes}
				renderInput={(params) => {
					return (
						<TextField
							{...params}
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							success={props.clientState === 'success'}
							error={props.clientState === 'error'}
							label={t('search_client_label')}
							autoComplete="disabled"
							fullWidth
						/>
					);
				}}
			/>
		</Tooltip>
	);
};

export default ClientSearchBar;
