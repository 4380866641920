import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
	useEffect(() => {
		const unlisten = history.listen(() => {
			window.sideContent?.current?.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		});
		return () => {
			unlisten();
		};
	}, []);

	return null;
}

export default withRouter(ScrollToTop);
