import { axiosInstance } from './apiInstance';
import { setUser } from 'actions/user';
import get from 'lodash/get';
import { reloadUser } from 'utils/functions';
import * as urls from './urls';

export const getPickupStats = (clientId) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getPickupStats, {
				params: {
					client_id: clientId,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getClientStats = (clientId) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getClientStats, {
				params: {
					client_id: clientId,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					let { credit_limit, credit_utilized } = response.data;
					let creditPerc =
						credit_limit > 0
							? credit_utilized > 0
								? (credit_utilized / credit_limit) * 100
								: 0
							: credit_utilized;
					let credit_percentage = creditPerc
						? parseFloat(creditPerc.toFixed(0))
						: 0;

					return {
						...response.data,
						credit_percentage,
					};
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getSlaReports = (clientId, daterange = {}) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getSlaReports, {
				params: {
					client_id: clientId,
					...daterange,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getTargetReports = (params) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getWeightTarget, {
				params: { ...params },
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
