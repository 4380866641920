import { axiosRatingInstance } from './apiInstance';
import { setShipmentRatingData } from 'actions/rating';
import { setUser } from 'actions/user';
import { reloadUser, readCookie } from 'utils/functions';
import * as urls from './urls';

export const getEligibleShipmentsForRating = (pageNo, isPopup = true) => {
	return (dispatch) => {
		let user = JSON.parse(readCookie('client'));
		delete user.permissions;
		delete user.groups;

		let obj = {
			user,
			page_size: isPopup ? 3 : 10,
			page: pageNo,
		};
		let body = JSON.stringify(obj);
		return axiosRatingInstance
			.post(urls.getShipmentsForRating, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201) {
					isPopup && dispatch(setShipmentRatingData(response.data.results));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const addRating = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosRatingInstance
			.post(urls.createRating, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
