import {
	SET_SHIPMENT_LIST,
	SET_SHIPMENT_EDIT_STATUS,
	SET_RECHARGE_FOR_SHIPMENT,
	SET_REPLAY_REQUEST,
	SET_ADDON_EMAIL,
} from 'actions/shipment';

const ACTION_HANDLERS = {
	[SET_SHIPMENT_LIST]: (state, action) => ({
		...state,
		shipmentList: action.payload,
	}),
	[SET_SHIPMENT_EDIT_STATUS]: (state, action) => {
		return {
			...state,
			shipmentEditStatus: action.payload.status,
			isEditingOrderStatus: action.payload.isEditingOrder,
			shipmentEditData: action.payload.data,
			orderErrors: action.payload.errors || undefined,
			highlightErrors: action.payload.highlightErrors || false,
		};
	},
	[SET_RECHARGE_FOR_SHIPMENT]: (state, action) => {
		return {
			...state,
			rechargeDetails: {
				...state.rechargeDetails,
				...action.payload,
			},
		};
	},
	[SET_REPLAY_REQUEST]: (state, action) => {
		return {
			...state,
			replayRequest: action.payload,
		};
	},
	[SET_ADDON_EMAIL]: (state, action) => {
		return {
			...state,
			add_on_emails: action.payload,
		};
	},
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { add_on_emails: [] };

export default function shipmentReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
